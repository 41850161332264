import React, { FC } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PixOutlinedIcon from "@mui/icons-material/PixOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import {
  TextoPix,
  TextoBoleto,
  CreditCard,
} from "../../components/TableProduct";
import { useTheme } from "@mui/material/styles";
import { Typography, Box, Paper, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ContainerBox from "../ContainerBox/ContainerBox";

type Props = {
  handle: any;
  vlr: string;
  setSendPayment: any;
};

const CardPayment: FC<Props> = ({ handle, vlr, setSendPayment }) => {
  const theme = useTheme();

  const themes = createTheme({
    palette: {
      text: {
        primary: "#001C47",
      },
      primary: {
        main: "#001C47",
      },
      secondary: {
        main: "#F08C10",
      },
    },
  });

  return (
    <ContainerBox sx={{ display: "flex", justifyContent: "center" }}>
      <Paper
        elevation={3}
        sx={{
          width: { xs: "360px", sm: "690px" },
          minHeight: "620px",
          overflow: "hidden",
          border: "1px solid #F08C10",
        }}
      >
        <Typography
          variant="h2"
          color="#000000"
          sx={{
            margin: "20px 0 0 20px",
          }}
        >
          Pagamento
        </Typography>
        <Typography
          variant="h2"
          color="#000000"
          sx={{
            marginLeft: "20px",
            fontSize: "14px",
            textTransform: "uppercase",
          }}
        >
          Escolha a forma de pagamento
        </Typography>
        <Paper
          variant="outlined"
          square
          sx={{
            margin: "20px 20px",
            height: "500px",
          }}
        >
          <ThemeProvider theme={themes}>
            <Box>
              <TabContext value={vlr}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    indicatorColor="secondary"
                    textColor="primary"
                    onChange={handle}
                    aria-label="container tabs"
                    variant="scrollable"
                  >
                    <Tab
                      label={
                        <Typography
                          id="btn-pagamento-pix"
                          variant="subtitle1"
                          fontWeight={theme.typography.fontWeightBold}
                          sx={{
                            fontSize: "16px",
                          }}
                        >
                          <PixOutlinedIcon fontSize="small" /> Pix
                        </Typography>
                      }
                      value="1"
                      sx={{
                        color: "#000000",
                        width: { xs: "150px", sm: "323px" },
                        height: "96px",
                        " &.Mui-selected": {
                          backgroundColor: "transparent",
                        },
                        "&:not(.Mui-selected)": {
                          color: "#ccc",
                        },
                      }}
                    />
                    <Tab
                      label={
                        <Typography
                          id="btn-pagamento-cartao-credito"
                          variant="subtitle1"
                          fontWeight={theme.typography.fontWeightBold}
                          sx={{
                            fontSize: "16px",
                          }}
                        >
                          <PaymentOutlinedIcon fontSize="small" /> Cartão de
                          Crédito
                        </Typography>
                      }
                      value="3"
                      sx={{
                        color: "#000000",
                        width: { xs: "150px", sm: "323px" },
                        height: "96px",
                        " &.Mui-selected": {
                          backgroundColor: "transparent",
                        },
                        "&:not(.Mui-selected)": {
                          color: "#ccc",
                        },
                      }}
                    />
                  </TabList>
                </Box>
                <TabPanel
                  value="0"
                  sx={{ fontFamily: "Red Hat Display, sans-serif" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      fontWeight={theme.typography.fontWeightBold}
                      sx={{
                        textAlign: "center",
                        margin: "30% 0",
                      }}
                    >
                      Selecione acima a forma de pagamento de sua preferência
                    </Typography>
                  </Box>
                </TabPanel>
                <TabPanel
                  value="1"
                  sx={{ fontFamily: "Red Hat Display, sans-serif" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextoPix />
                  </div>
                </TabPanel>
                <TabPanel
                  value="3"
                  sx={{
                    height: { xs: "400px", sm: "500px" },
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <CreditCard setSendPayment={setSendPayment} />
                  <Typography
                    variant="subtitle2"
                    color="#000000"
                    sx={{
                      margin: "45px 0",
                      lineHeight: "19px",
                      textAlign: "justify",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    <p>
                      Mesmo com múltiplas pequenas parcelas, o valor total não
                      pode exceder o limite do seu cartão. Essa é a regra de
                      aprovação das administradoras de cartão de crédito.
                    </p>
                    <p>
                      Após a finalização não será mais possível adicionar ou
                      retirar itens do carrinho
                    </p>
                    <p>
                      O pagamento das parcelas só será creditada depois que
                      identificamos o pagamento. Caso não seja pago, seu pedido
                      será cancelado.
                    </p>
                  </Typography>
                </TabPanel>
              </TabContext>
            </Box>
          </ThemeProvider>
        </Paper>
      </Paper>
    </ContainerBox>
  );
};

export default CardPayment;
