const TextoPremios = () => {
  return (
    <div>
      <p>Saiba mais sobre os prêmios do Baú da Felicidade Jequiti.</p>
      <p>
        Com enorme credibilidade entre os brasileiros e que há mais de 60 anos
        já premiou milhares de pessoas em todo Brasil e continua premiando até
        hoje. Cada carnê vem com 12 mensalidades de R$20,00 que devem ser pagas
        nos pontos oficiais do BAÚ da Felicidade:
      </p>
      <ul>
        <li>Nas Casas Lotéricas</li>
        <li>Nas Agências dos Correios</li>
        <li>Nas lojas Lebes</li>
        <li>Nos representantes Caixa Aqui</li>
        <li>
          Por meio do site{" "}
          <a href="www.bau.jequiti.com.br" target="_blank">
            www.bau.jequiti.com.br
          </a>
        </li>
      </ul>
      <p>
        Também é possível pagar as parcelas do seu carnê com o seu cartão de
        crédito todos os meses de forma automática ou por meio do PIX com QR
        CODE disponível em cada uma das parcelas. Ligue 0800 545 2121 e
        informe-se com os nossos atendentes.Ligue <strong>0800 545 2121</strong>{" "}
        e informe-se com os nossos atendentes.
      </p>
      <p>
        A cada parcela paga você terá direito a 2 cupons para participar dos
        programas de TV do BAÚ no SBT e concorrer a prêmios diários TODOS OS
        DIAS!
      </p>
      <p>
        Ao pagar as mensalidades você deve destacar os seus cupons e enviar para
        o SBT. Você pode depositar os seus cupons nas urnas presentes nas casas
        Lotéricas, agências dos Correios e lojas Lebes.
      </p>
      <p>
        <strong>COM O BAÚ VOCÊ SEMPRE GANHA!</strong>
      </p>
      <p>
        Além de concorrer a participação nos programas de TV do BAÚ no SBT, ao
        final das 12 parcelas pagas, você poderá entrar em contato com o BAÚ nos
        canais oficiais de e retirar mais do que pagou em excelentes produtos
        Jequiti a sua escolha. São R$240,00 disponíveis ao término do seu carnê
        e o BAÚ te dá de presente mais R$24,00 completando assim um saldo de
        R$264,00 para você receber o seu dinheiro de volta em produtos Jequiti e
        a entrega é grátis para qualquer lugar do Brasil.
      </p>
      <p>
        <strong>Como resgatar o seu carnê do Baú:</strong>
      </p>
      <ul>
        <li>
          Por meio do site:{" "}
          <a href="www.bau.jequiti.com.br" target="_blank">
            www.bau.jequiti.com.br
          </a>
        </li>
        <li>
          Por meio do WhatsApp Oficial do BAÚ (Kits Exclusivos) -{" "}
          <a
            href="https://api.whatsapp.com/send?phone=5511953020985"
            target="_blank"
            rel="noreferrer"
          >
            11 95302-0985
          </a>
        </li>
        <li>
          Por meio da Central de Atendimento: <strong>0800 545 2121</strong>{" "}
          (Ligação gratuita)
        </li>
      </ul>
      <p>
        <strong>Onde comprar o Carnê do Baú da Felicidade Jequiti:</strong>
      </p>
      <ul>
        <li>
          Site:{" "}
          <a href="www.bau.jequiti.com.br" target="_blank">
            www.bau.jequiti.com.br
          </a>
        </li>
        <li>
          WhatsApp:
          <a href="http://bit.ly/bauwhatsapp" target="_blank" rel="noreferrer">
            <u>11 95302-0985</u>
          </a>
        </li>
        <li>
          Central de Atendimento: <strong>0800 545 2121</strong> (ligação
          gratuita)
        </li>
        <li>Nas Agências dos Correios</li>
        <li>Nas Casas Lotéricas</li>
        <li>Nas lojas Lebes</li>
        <li>Com as Consultoras Jequiti</li>
      </ul>
      <p>
        <strong>Fui Sorteado! E agora?</strong>
      </p>
      <p>
        Fique tranquilo, o setor de prêmios do Baú da Felicidade entra em
        contato com você. Por isso é muito importante manter seu cadastro com a
        gente sempre atualizado. Você pode solicitar a confirmação dos seus
        dados de cadastro por meio da Central de Atendimento{" "}
        <strong>0800 545 2121</strong>, no site{" "}
        <a href="www.bau.jequiti.com.br" target="_blank">
          <u>bau.com.br</u>
        </a>{" "}
        e no WhatsApp Oficial do BAÚ <strong>(11) 95302-0985</strong>
      </p>
      <p>
        <strong>
          Fique por dentro da programação e dos sorteios do Baú da Felicidade:
        </strong>
      </p>
      <p>
        O Programa Giro do Baú tem sorteios TODOS os Domingos com os prêmios de
        R$ 20, 30 e 50 mil! Acompanhe nossos sorteios na programação do SBT, e
        no nosso canal do YouTube. Mantenha o seu carnê em dia e boa sorte!"
      </p>
      <p>
        O Programa Show de Prêmios do Baú tem sorteios diários: de segunda à
        sábado você pode ganhar incríveis eletroeletrônicos e aos domingos você
        pode faturar R$ 45 mil, com os prêmios cumulativos até sair um novo
        ganhador. Acompanhe nossos sorteios na programação do SBT, e no nosso
        canal do YouTube. <br />
        Mantenha o seu carnê em dia e boa sorte!
      </p>
    </div>
  );
};

export default TextoPremios;
