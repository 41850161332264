import React, { useState, useEffect, useCallback } from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import {
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Skeleton,
  Box,
} from "@mui/material";
import { useIsMobile, cepMask, validateName } from "../../utils/utils";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useAuth } from "../../context/AuthProvider/useAuth";
import EnderecoCard from "../EnderecoCard";
import ContainerBox from "../ContainerBox";
import ErroSemResultados from "../ErroSemResultados";
import { useHandleParcela } from "../../context/ContextParcelas/userContextParcelas";

type Endereco = {
  cep: string;
  nome: string;
  endereco: string;
  numero: string;
  cidade: string;
  estado: string;
  bairro: string;
  complemento: string;
  referencia: string;
  principal: boolean;
};

const MeusEnderecos = () => {
  const [listaEnderecos, setListaEnderecos] = useState<Endereco[]>([]);
  const auth = useAuth();
  const isMobile = useIsMobile();
  const { setMenuSidebar } = useHandleParcela();
  const [formData, setFormData] = useState({
    id: "",
    cep: "",
    nome: "",
    endereco: "",
    bairro: "",
    cidade: "",
    estado: "",
    numero: "",
    complemento: "",
    referencia: "",
    principal: false,
  });
  const [errorCep, setErroCep] = useState(false);
  const [errorName, setErroName] = useState(false);
  const [errorNumber, setErrorNumber] = useState(false);
  const [errorComplemento, setErrorComplemento] = useState(false);
  const [errorReferencia, setErrorReferencia] = useState(false);
  const [errorEndereco, setErrorEndereco] = useState(false);
  const [errorBairro, setErrorBairro] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [token, setToken] = useState("");
  const [getItems, setGetItems] = useState<null | any>(null);
  const [isModified, setIsModified] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState<"success" | "error" | null>(
    null
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [putData, setPutData] = React.useState<null | any>(null);
  const [novoBairro, setNovoEndereco] = useState(false);
  const [enderecoData, setEnderecoData] = useState<null | any>(null);
  const [getEndereco, setGetEndereco] = useState<null | any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 3;
  const indexOfLastCard = currentPage * cardsPerPage;
  const [fullName, setFullName] = useState("");
  const [editCard, setEditCard] = useState(false);
  const [insertCard, setInsertCard] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const validateInput = useCallback(() => {
    return (
      formData.cep === "" ||
      formData.numero === "" ||
      formData.referencia === "" ||
      formData.endereco === "" ||
      formData.bairro === "" ||
      errorCep ||
      errorName ||
      errorNumber ||
      errorReferencia ||
      errorEndereco ||
      errorBairro
    );
  }, [
    formData.cep,
    formData.numero,
    formData.referencia,
    formData.endereco,
    formData.bairro,
    errorCep,
    errorName,
    errorNumber,
    errorReferencia,
    errorEndereco,
    errorBairro,
  ]);

  const [isDisabled, setIsDisabled] = useState(validateInput());
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      handleDescript(token);
      setToken(token);
    }
  }, []);

  useEffect(() => {
    if (getEndereco) {
      setFormData((prevState) => ({
        ...prevState,
        endereco: getEndereco?.rua,
        bairro: getEndereco?.bairro,
        cidade: getEndereco?.cidade,
        estado: getEndereco?.uf,
      }));
    }
  }, [getEndereco]);

  const handleDescript = async (item: any) => {
    try {
      if (auth) {
        const request = await auth?.descriptToken(item);
        handleData(request);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleData = async (token: any) => {
    const login = token.login;
    setLoading(true);
    try {
      if (auth) {
        const request = await auth?.searchClient(login);
        handleArray(request);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    if (getItems && Array.isArray(getItems.enderecos)) {
      const novosEnderecos: Endereco[] = getItems.enderecos.map(
        (endereco: any) => ({
          cep: endereco.cep || "",
          nome: fullName || "",
          endereco: endereco.rua || "",
          bairro: endereco.bairro || "",
          cidade: endereco.cidade || "",
          uf: endereco.uf || "",
          numero: endereco.numero || "",
          complemento: endereco.complemento || "",
          referencia: endereco.referencia || "",
          principal: endereco.principal || false,
        })
      );
      setListaEnderecos((prevEnderecos) => [
        ...prevEnderecos,
        ...novosEnderecos,
      ]);
    }
  }, [getItems]);

  const handleArray = (item: any) => {
    setEnderecoData(item.enderecos);
    setGetItems(item);
    setFullName(item.nome + " " + item.sobrenome);
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target;
    const cep = event.target.value.replace(/\D/g, "");
    setFormData({ ...formData, [name]: cepMask(value) });
    setErroCep(!event.target.value);
    setLoading(true);

    if (cep.length === 8) {
      const req = await auth?.searchAddress(cep);
      handleEndGenerico(req);
      setGetEndereco(req);
      setLoading(false);
    } else {
      setErroCep(true);
      setLoading(false);
    }
  };

  const handleEndGenerico = (item: any) => {
    if (item.rua === "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleName = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value.replace(/[0-9]/g, "") });
    setErroName(!validateName(value) || !event.target.value);
  };

  const handleNumber = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrorNumber(!event.target.value);
  };

  const handleComplemento = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrorComplemento(!event.target.value);
  };

  const handleReferencia = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrorReferencia(!event.target.value);
  };

  const handleEndereco = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrorEndereco(!event.target.value);
  };

  const handleBairro = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrorBairro(!event.target.value);
  };

  const handleRequestReturn = () => {
    handleDescript(token);
    setEditCard(false);
    setInsertCard(false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    event.preventDefault();
    const tipoEndereco = getItems.enderecos[0]?.tipo ?? 1;
    const data = {
      id: getItems.id,
      idPreCupom: getItems.idPreCupom,
      nome: getItems.nome,
      sobrenome: getItems.sobrenome,
      nascimento: getItems.nascimento,
      sexo: getItems.sexo,
      idUsuarioInclusao: getItems.idUsuarioInclusao,
      idUsuarioConectado: getItems.idUsuarioConectado,
      dataInclusao: getItems.dataInclusao,
      idUsuarioAtualizacao: getItems.idUsuarioAtualizacao,
      dataAtualizacao: getItems.dataAtualizacao,
      sistemaOrigem: getItems.sistemaOrigem,
      contatos: [
        { tipo: 1, valor: getItems.contatos[0]?.valor },
        { tipo: 2, valor: getItems.contatos[1]?.valor },
      ],
      documentos: [{ tipo: 1, valor: getItems.documentos[0].valor }],
      enderecos: [
        {
          bairro: formData.bairro,
          cidade: formData.cidade,
          rua: formData.endereco,
          uf: formData.estado,
          tipo: tipoEndereco,
          cep: formData.cep,
          numero: formData.numero,
          complemento: formData.complemento,
          referencia: formData.referencia,
          principal: true,
        },
      ],
    };

    try {
      if (auth) {
        const putData = await auth?.putClient(data, token);
        console.log("data", data);
        setLoading(false);
        setPutData(putData);
        setIsModified(false);
        setAlertMessage("Endereço adicionado com sucesso!");
        setAlertStatus("success");
        setAlertOpen(true);
        setNovoEndereco(true);
        handleRequestReturn();
      }
    } catch (error: any) {
      setLoading(false);
      setAlertMessage(`${error}`);
      setAlertStatus("error");
      setAlertOpen(true);
      throw error;
    }
  };

  useEffect(() => {
    const validateAndSetIsDisabled = () => {
      setIsDisabled(validateInput());
    };

    validateAndSetIsDisabled();
  }, [
    formData.cep,
    formData.nome,
    formData.numero,
    formData.complemento,
    formData.referencia,
    formData.principal,
    errorCep,
    errorName,
    errorNumber,
    errorComplemento,
    errorPhone,
    validateInput,
  ]);

  const handleReturn = () => {
    setEditCard(false);
    setInsertCard(false);
  };

  return (
    <ContainerBox>
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: `${isMobile ? "300px" : "454px"}`,
            }}
          >
            <Typography variant="h1">
              {isMobile && (
                <ArrowBackIosIcon
                  onClick={() => setMenuSidebar(false)}
                  sx={{ fontSize: "32px" }}
                />
              )}
              Meus Endereços
            </Typography>
          </div>
        </Grid>
      </Grid>
      {editCard || insertCard ? (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "center", md: "left" },
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                width: `${isMobile ? "300px" : "454px"}`,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="#000000"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                  }}
                >
                  CEP*
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="#000000"
                  sx={{
                    marginTop: "20px",
                    fontSize: "12px",
                    "& a": {
                      color: "#000000",
                    },
                  }}
                >
                  *Não sabe seu CEP?
                  <a href="https://buscacepinter.correios.com.br/app/endereco/index.php">
                    {" "}
                    <strong>Consulte aqui.</strong>
                  </a>
                </Typography>
              </div>
              <TextField
                disabled={editCard}
                sx={{
                  boxSizing: "border-box",
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "4px",
                  width: "100%",
                }}
                type="text"
                name="cep"
                value={formData.cep}
                onChange={handleChange}
                placeholder="00000-000"
                required
                error={errorCep || formData.cep === ""}
                helperText={
                  errorCep || formData.cep === "" ? "Campo Obrigatório" : ""
                }
                inputProps={{ maxLength: 9 }}
                InputProps={{
                  endAdornment:
                    errorCep || !formData.cep ? null : loading ? (
                      <CircularProgress sx={{ color: "#F08C10" }} />
                    ) : (
                      <CheckOutlinedIcon
                        sx={{ color: "orange", marginRight: "8px" }}
                      />
                    ),
                  style: {
                    height: "48px",
                  },
                }}
              />
              {true && (
                <form onSubmit={handleSubmit}>
                  <Typography
                    variant="subtitle2"
                    color="#939598"
                    sx={{
                      textTransform: "uppercase",
                      marginTop: "20px",
                      fontSize: "10px",
                      fontWeight: 700,
                    }}
                  >
                    Nome Completo*
                  </Typography>
                  <TextField
                    disabled
                    sx={{
                      boxSizing: "border-box",
                      background: "rgba(255, 255, 255, 0.1)",
                      borderRadius: "4px",
                      width: "100%",
                    }}
                    type="text"
                    name="nome"
                    value={fullName}
                    placeholder="Nome Sobrenome"
                    required
                    error={errorName}
                    helperText={
                      errorName ? "Nome e sobrenome são obrigatórios" : ""
                    }
                    InputProps={{
                      endAdornment:
                        errorName || !formData.nome ? null : (
                          <CheckOutlinedIcon
                            sx={{
                              width: "18px",
                              height: "18px",
                              color: "#F08C10",
                              marginRight: "6px",
                            }}
                          />
                        ),
                      style: {
                        height: "48px",
                      },
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    color="#939598"
                    sx={{
                      textTransform: "uppercase",
                      marginTop: "20px",
                      fontSize: "10px",
                      fontWeight: 700,
                    }}
                  >
                    Endereço
                  </Typography>
                  <TextField
                    disabled={disabled}
                    sx={{
                      boxSizing: "border-box",
                      background: "rgba(255, 255, 255, 0.1)",
                      borderRadius: "4px",
                      width: "100%",
                    }}
                    type="text"
                    name="endereco"
                    onChange={handleEndereco}
                    value={formData.endereco}
                    placeholder="Rua, Av, Tv ..."
                    required
                    error={
                      (!disabled && errorEndereco) ||
                      (!disabled && formData.endereco === "")
                    }
                    helperText={`${
                      !disabled && formData.endereco === ""
                        ? "Campo Obrigatório"
                        : !disabled && formData.endereco !== ""
                        ? ""
                        : "Campo controlado pelo CEP"
                    }`}
                    InputProps={{
                      endAdornment: !formData.endereco ? null : (
                        <CheckOutlinedIcon
                          sx={{
                            width: "18px",
                            height: "18px",
                            color: "#F08C10",
                            marginRight: "6px",
                          }}
                        />
                      ),
                      style: {
                        height: "48px",
                      },
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="#000000"
                          sx={{
                            textTransform: "uppercase",
                            marginTop: "20px",
                            fontSize: "10px",
                            fontWeight: 700,
                          }}
                        >
                          Número*
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="#939598"
                          sx={{
                            marginTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          Max 10 caracteres*
                        </Typography>
                      </div>
                      <TextField
                        sx={{
                          boxSizing: "border-box",
                          background: "rgba(255, 255, 255, 0.1)",
                          borderRadius: "4px",
                          width: { xs: "150px", sm: "150px", md: "200px" },
                        }}
                        type="text"
                        name="numero"
                        value={formData.numero}
                        onChange={handleNumber}
                        placeholder="000"
                        error={errorNumber || formData.numero === ""}
                        helperText={
                          errorNumber || formData.numero === ""
                            ? "Campo Obrigatório"
                            : ""
                        }
                        required
                        inputProps={{
                          maxLength: 10,
                        }}
                        InputProps={{
                          endAdornment:
                            errorNumber || !formData.numero ? null : (
                              <CheckOutlinedIcon
                                sx={{
                                  width: "18px",
                                  height: "18px",
                                  color: "#F08C10",
                                  marginRight: "6px",
                                }}
                              />
                            ),
                          style: {
                            height: "48px",
                          },
                        }}
                      />
                    </div>
                    <div>
                      <Typography
                        variant="subtitle2"
                        color="#000000"
                        sx={{
                          textTransform: "uppercase",
                          marginTop: "20px",
                          fontSize: "10px",
                          fontWeight: 700,
                        }}
                      >
                        Complemento
                      </Typography>
                      <TextField
                        sx={{
                          boxSizing: "border-box",
                          background: "rgba(255, 255, 255, 0.1)",
                          borderRadius: "4px",
                          width: { xs: "150px", sm: "150px", md: "200px" },
                        }}
                        type="text"
                        name="complemento"
                        value={formData.complemento}
                        onChange={handleComplemento}
                        placeholder="Casa, Apt, Blc"
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="#939598"
                          sx={{
                            textTransform: "uppercase",
                            marginTop: "20px",
                            fontSize: "10px",
                            fontWeight: 700,
                          }}
                        >
                          Bairro*
                        </Typography>
                      </div>
                      <TextField
                        disabled={disabled}
                        sx={{
                          boxSizing: "border-box",
                          background: "rgba(255, 255, 255, 0.1)",
                          borderRadius: "4px",
                          width: { xs: "150px", sm: "150px", md: "200px" },
                        }}
                        type="text"
                        name="bairro"
                        onChange={handleBairro}
                        value={formData.bairro}
                        placeholder="Jd América"
                        required
                        error={
                          (!disabled && errorBairro) ||
                          (!disabled && formData.bairro === "")
                        }
                        helperText={`${
                          !disabled && formData.bairro === ""
                            ? "Campo Obrigatório"
                            : !disabled && formData.bairro !== ""
                            ? ""
                            : "Campo controlado pelo CEP"
                        }`}
                        // inputProps={{
                        //   maxLength: 10,
                        // }}
                        InputProps={{
                          endAdornment: !formData.bairro ? null : (
                            <CheckOutlinedIcon
                              sx={{
                                width: "18px",
                                height: "18px",
                                color: "#F08C10",
                                marginRight: "6px",
                              }}
                            />
                          ),
                          style: {
                            height: "48px",
                          },
                        }}
                      />
                    </div>
                    <div>
                      <Typography
                        variant="subtitle2"
                        color="#000000"
                        sx={{
                          textTransform: "uppercase",
                          marginTop: "20px",
                          fontSize: "10px",
                          fontWeight: 700,
                        }}
                      >
                        Ponto Referência*
                      </Typography>
                      <TextField
                        required
                        error={errorReferencia || formData.referencia === ""}
                        helperText={
                          errorReferencia || formData.referencia === ""
                            ? "Campo Obrigatório"
                            : ""
                        }
                        sx={{
                          boxSizing: "border-box",
                          background: "rgba(255, 255, 255, 0.1)",
                          borderRadius: "4px",
                          width: { xs: "150px", sm: "150px", md: "200px" },
                        }}
                        type="text"
                        name="referencia"
                        placeholder="Prox ao colégio"
                        value={formData.referencia}
                        onChange={handleReferencia}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="#939598"
                          sx={{
                            textTransform: "uppercase",
                            marginTop: "20px",
                            fontSize: "10px",
                            fontWeight: 700,
                          }}
                        >
                          Cidade*
                        </Typography>
                      </div>
                      <TextField
                        placeholder="São Paulo"
                        disabled
                        sx={{
                          boxSizing: "border-box",
                          background: "rgba(255, 255, 255, 0.1)",
                          borderRadius: "4px",
                          width: { xs: "150px", sm: "150px", md: "200px" },
                        }}
                        type="text"
                        name="cidade"
                        value={formData.cidade}
                        helperText="Campo controlado pelo CEP"
                        required
                        inputProps={{
                          maxLength: 10,
                        }}
                        InputProps={{
                          endAdornment: (
                            <CheckOutlinedIcon
                              sx={{
                                width: "18px",
                                height: "18px",
                                color: "#F08C10",
                                marginRight: "6px",
                              }}
                            />
                          ),
                          style: {
                            height: "48px",
                          },
                        }}
                      />
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="#939598"
                          sx={{
                            textTransform: "uppercase",
                            marginTop: "20px",
                            fontSize: "10px",
                            fontWeight: 700,
                          }}
                        >
                          Estado*
                        </Typography>
                      </div>
                      <TextField
                        disabled
                        placeholder="UF"
                        sx={{
                          boxSizing: "border-box",
                          background: "rgba(255, 255, 255, 0.1)",
                          borderRadius: "4px",
                          width: { xs: "150px", sm: "150px", md: "200px" },
                        }}
                        type="text"
                        name="estado"
                        value={formData.estado}
                        helperText="Campo controlado pelo CEP"
                        required
                        inputProps={{
                          maxLength: 10,
                        }}
                        InputProps={{
                          endAdornment: (
                            <CheckOutlinedIcon
                              sx={{
                                width: "18px",
                                height: "18px",
                                color: "#F08C10",
                                marginRight: "6px",
                              }}
                            />
                          ),
                          style: {
                            height: "48px",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <Button
                    type="submit"
                    disabled={isDisabled || loading}
                    sx={{
                      width: "100%",
                      height: "48px",
                      margin: "30px 0",
                      fontSize: "14px",
                      color: "#FFFF",
                      background: `${
                        isDisabled || loading ? "#D1D1D1" : "#F08C10"
                      }`,
                      "&: hover": {
                        background: `${
                          isDisabled || loading ? "#D1D1D1" : "#F08C10"
                        }`,
                      },
                    }}
                  >
                    {loading ? (
                      <CircularProgress sx={{ color: "#FFFFFF" }} />
                    ) : editCard ? (
                      "ATUALIZAR ENDEREÇO"
                    ) : (
                      "ADICIONAR ENDEREÇO"
                    )}
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "5px 0",
                    }}
                  >
                    <Typography
                      color="#1C1C7B"
                      onClick={handleReturn}
                      sx={{
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: 700,
                        lineHeight: "16px",
                      }}
                    >
                      {"< Voltar"}
                    </Typography>
                  </div>
                </form>
              )}
            </div>
          </Grid>
        </Grid>
      ) : enderecoData && enderecoData.length > 0 ? (
        <EnderecoCard
          endereco={enderecoData}
          setEditCard={setEditCard}
          setInsertCard={setInsertCard}
          setFormData={setFormData}
        />
      ) : loading ? (
        <Skeleton variant="rectangular" width={455} height={139} />
      ) : enderecoData && enderecoData.length === 0 ? (
        <div
          style={{
            display: "flex",
            maxWidth: `${isMobile ? "auto" : "862px"}`,
          }}
        >
          <ErroSemResultados setInsertCard={setInsertCard} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            maxWidth: `${isMobile ? "auto" : "862px"}`,
          }}
        >
          <ErroSemResultados setInsertCard={setInsertCard} />
        </div>
      )}
    </ContainerBox>
  );
};

export default MeusEnderecos;
