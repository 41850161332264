export const regulamento = [
  {
    label: 'Carnê do Baú',
    id: '0',
    doc: 'institucional/regulamento-novo-carne-bau.pdf',
    description: ` <p>
                        <strong>
                          REGULAMENTO PARA VENDA DE MERCADORIAS A VAREJO, MEDIANTE OFERTA
                          PÚBLICA E RECEBIMENTO PARCIAL OU TOTAL, DO RESPECTIVO PREÇO DENOMINADO
                          “CARNÊ DO BAÚ DA FELICIDADE JEQUITI”.
                        </strong>
                      </p>
                      <p>
                        <strong>
                          SECAP/ME Nº 13/2024
                        </strong>
                        <strong>
                        PROCESSO Nº 14022.089036/2024-66
                        </strong>
                      </p>
                      <p>
                        <strong>
                          1. NOME, END. E INSCRIÇÃO NO CNPJ/ME.
                        </strong><br>
                        SS Comércio de Cosméticos e Produtos de Higiene Pessoal Ltda. – <strong>“JEQUITI” </strong><br>
                        Avenida das Comunicações, 927, Pavilhão B (4) - Galpão 01- Bairro Industrial.<br>
                        Osasco/ SP – CEP 06276-906 – CNPJ/MF: 07.278.350/0001-63<br>
                        FONE: 0800 718 9700 e 4004-3846 – SITE: <strong><a href='https://bau.jequiti.com.br/' target='_blank'>bau.jequiti.com.br</a></strong><br>
                      </p>
                      <p>
                        <strong>2. NOME DA PROMOÇÃO:</strong> “Carnê do Baú da Felicidade Jequiti”.
                      <p>
                      <p>
                        <strong>3. ÁREA DE EXECUÇÃO DA PROMOÇÃO:</strong>  Todo o território nacional.
                      <p>
                      <p>
                        <strong>4. PERÍODO DE EXECUÇÃO DA OPERAÇÃO: </strong> 01/12/2024 A 01/12/2025.
                      <p>
                      <p>
                        4.1. Os prazos do presente regulamento serão computados com base nos pagamentos de <br>
                        todas as mensalidades que deverão ser efetuados dentro do período de validade do <br>
                        carnê, ou seja, dentro dos 12 (dez) meses a contar da data do pagamento da 1ª (primeira) <br>
                        parcela do carnê.
                      </p>
                      <p>
                        <strong>5. NÚMERO TOTAL DE CARNÊs</strong>
                      <p>
                      <p>
                        5.1. Para essa promoção, serão utilizados 1.000.000 (um milhão) de carnês, <br>
                        com 10 (dez) séries diferentes, tendo em vista que cada série permite <br>
                        gerar 100.000 (cem mil carnês), ressaltando ressaltando que cada carnê terá 12 parcelas e<br>
                        e cada parcela terá o valor unitário de R$ 20,00 (vinte reais), terá o carnê o valor <br>
                        total de R$ 240,00 (duzentos e quarenta reais), com prazo de validade de 12 (doze) meses, <br>
                        a contar da data do pagamento da 1ª (primeira) parcela.
                      <p>
                      <p>
                      5.2. Caso a quantidade de carnês emitidos não seja suficiente para o período da promoção ou não <br>
                      seja atingido o volume de vendas pretendido, a JEQUITI informará imediatamente à SECAP, visando o <br>
                      aditamento e/ou providências administrativas de regularização.
                      </p>
                      <p>
                        <strong>6. DO OBJETO E CADASTRO DA PROMOÇÃO</strong>
                      <p>
                      <p>
                        6.1. O titular do plano poderá, ao final do pagamento das 12 (doze) parcelas, resgatar em <br>
                        produtos o valor pago, devidamente corrigido pela TR, tendo como sugestão o resgate de produtos <br>
                      constantes no catálogo próprio do Carnê do Baú ou outra mercadoria escolhida, desde que disponível em <br>
                      estoque e que não esteja alocado pela vendedora para cumprimento de estratégias promocionais ou kits de <br>
                      datas comemorativas direcionadas às suas consultoras de venda.
                      <p>
       `,
  },
  {
    label: 'Giro do Baú',
    img: false,
    id: '1',
    doc: 'institucional/Regulamento - Giro do Baú.pdf',
    nameRegulamento: 'Giro do Baú',
    description: `
                      <p>
                      <strong><a href="https://scpc.seae.fazenda.gov.br/scpc/login.jsf"

                        target='_blank'>Critério de participação <a/> </strong>
                        </p>
                        <p>
                          Promoção "Giro do Baú:
                      </p>
                      <p>
                        Para participar da promoção o interessado deverá efetuar a compra de um Carnê do Baú da <br/>
                        Felicidade Jequiti, efetuar em dia o pagamento das parcelas, realizar o cadastro e vínculo do <br/>
                        carnê nos canais de atendimento da empresa, preencher o cupom, enviar via correios. Após a <br/>
                        identificação do pagamento das parcelas, o baú poderá imprimir os cupons do cliente <br/>
                        (modalidade digital) e depositar nas urnas de sorteio
                      </p>
                      <p>
                      Os cupons retirados nos dias dos sorteios voltarão à urna.
                      </p>
                      <p>
                      O período para envio dos respectivos cupons será a partir do dia 01/12/2024 até 26/01/2025.
                      <p>
                      <p>
                          Será sorteado um prêmio entre os valores de R$ 20.000,00 (vinte mil reais) a R$ 50.000,00
                        (cinquenta mil reais)
                      <p>
                      <br/>
                      <p>
                        Segue abaixo dinâmica do sorteio:
                      <p>
                      <p>
                        Semanalmente (todos os domingos), 1 (um) cliente pré-sorteado disputará o prêmio de até <br/>
                          50.000,00 (Cinquenta mil reais).
                      </p>
                      <p>
                        O apresentador do programa e ou convidado especial rodará 1 vez o "giro do Baú" pelo cliente<br/>
                        pré-sorteado (uma roleta contendo sequencialmente os prêmios de R$ 50.000,00, R$ 30.000,00, <br/>
                        R$ 20.000,00, R$ 50.000,00, R$ 30.000,00, R$ 20.000,00, R$ 50.000,00, R$ 30.000,00, R$<br/>
                        20.000,00, R$ 50.000,00, R$ 30.000,00, R$ 20.000,00), onde a "bolinha" parar será o prêmio <br/>
                        que o cliente irá receber.
                      </p>

       `,
  },
  {
    label: 'Pião da Felicidade',
    img: true,
    id: '2',
    doc: 'institucional/Regulamento-Piao-da-Felicidade.pdf',
    numeroCertificado: 'SPA/ME Nº 06.035110/2024',
    nameRegulamento: 'Pião da Felicidade do Baú',
    description: `
                      <p>
                        1 - EMPRESAS PROMOTORAS:
                      </p>
                      <p>
                      1.1 - Empresa Mandatária:
                      Razão Social: SS COMERCIO DE COSMETICOS E PRODUTOS DE HIGIENE PESSOAL LTDA<br>
                      Endereço: DAS COMUNICACOES Número: 927 Complemento: GALPAOINDUSTRIAL PAVLH B (4)<br>
                      Bairro: INDUSTRIAL ANHANGUERA Município: OSASCO UF: SP CEP:06276-906<br>
                      CNPJ/MF nº: 07.278.350/0001-63
                      </p>
                      <p>
                        2 - MODALIDADE DA PROMOÇÃO:<br>
                        Assemelhado a Concurso
                      </p>
                      <p>
                        3 - ÁREA DE ABRANGÊNCIA:<br>
                        Todo o território nacional.
                      <p>
                      <p>
                        4 - PERÍODO DA PROMOÇÃO:<br>
                        05/07/2024 a 15/09/2024
                      <p>
                      <p>
                        5 - PERÍODO DE PARTICIPAÇÃO:<br>
                        05/07/2024 a 14/09/2024
                      <p>
                      <p>
                        6 - CRITÉRIO DE PARTICIPAÇÃO:<br>
                        Promoção "Pião da Felicidade"
                      </p>
                      <p>
                      Para participar da promoção o interessado deverá efetuar a compra de um Carnê do Baú da Felicidade Jequiti, efetuar em dia o pagamento das parcelas, preencher o cupom e depositá-lo nas urnas localizadas nos pontos de comercialização do Carnê. Após a identificação do pagamento das parcelas, a empresa promotora irá imprimir os cupons do cliente (modalidade digital) e depositar na urna de sorteio.
                      </p>
                      <p>
                      Os cupons retirados nos dias dos sorteios voltarão à urna. <br/> O período para envio dos respectivos cupons a partir do dia 07/07/2024 até 25/08/2024.
                      </p>
       `,
  },
  {
    label: 'MEGA SORTEIO',
    img: true,
    id: '3',
    doc: 'institucional/Regulamento-Mega-Sorteio-do-Bau.pdf',
    nameRegulamento: 'Show de Prêmios do Baú – Mega sorteio da Felicidade',
    numeroCertificado: 'SPA/ME Nº 06.035105/2024',
    description: `
                      <p>
                        1 - EMPRESAS PROMOTORAS:
                      </p>
                      <p>
                      1.1 - Empresa Mandatária:
                      Razão Social: SS COMERCIO DE COSMETICOS E PRODUTOS DE HIGIENE PESSOAL LTDA<br>
                      Endereço: DAS COMUNICACOES Número: 927 Complemento: GALPAOINDUSTRIAL PAVLH B (4)<br>
                      Bairro: INDUSTRIAL ANHANGUERA Município: OSASCO UF: SP CEP:06276-906<br>
                      CNPJ/MF nº: 07.278.350/0001-63
                      </p>
                      <p>
                        2 - MODALIDADE DA PROMOÇÃO:<br>
                        Assemelhado a Concurso
                      </p>
                      <p>
                        3 - ÁREA DE ABRANGÊNCIA:<br>
                        Todo o território nacional.
                      <p>
                      <p>
                        4 - PERÍODO DA PROMOÇÃO:<br>
                        07/07/2024 a 22/09/2024
                      <p>
                      <p>
                        5 - PERÍODO DE PARTICIPAÇÃO:<br>
                       07/07/2024 a 20/09/2024
                      <p>
                      <p>
                        6 - CRITÉRIO DE PARTICIPAÇÃO
                      </p>
                      <p>
                      Para participar da promoção o interessado deverá efetuar a compra de um Carnê do Baú da Felicidade Jequiti, efetuar em dia o pagamento
                      das parcelas, preencher o cupom e depositá-lo nas urnas localizadas nos pontos de comercialização do Carnê.  No caso de carnês comercializados na modalidade digital, após a identificação do pagamento das respectivas parcelas, os cupons serão impressos pela empresa promotora e serão depositados na urna do sorteio.
                      </p>
                      <p>
                      Os cupons retirados nos dias dos sorteios voltarão à urna. <br/> O período para envio dos respectivos cupons a partir do dia 07/07/2024 até 20/09/2024, sendo o sorteio realizado no dia 22/09/2024 e a dinâmica para concorrer ao prêmio entre os ganhadores será realizada presencialmente no dia 29/09/2024.
                      </p>
       `,
  },
  {
    label: 'Show de Prêmios',
    img: true,
    id: '4',
    doc: 'institucional/Regulamento_Show_Prêmios.pdf',
    numeroCertificado: 'SRE/ME Nº 06.032569/2024',
    nameRegulamento: 'Show de Prêmios do Baú',
    description: `
                      <p>
                        1 - EMPRESAS PROMOTORAS:
                      </p>
                      <p>
                      1.1 - Empresa Mandatária:
                      Razão Social: SS COMERCIO DE COSMETICOS E PRODUTOS DE HIGIENE PESSOAL LTDA<br>
                      Endereço: DAS COMUNICACOES Número: 927 Complemento: GALPAOINDUSTRIAL PAVLH B (4)<br>
                      Bairro: INDUSTRIAL ANHANGUERA Município: OSASCO UF: SP CEP:06276-906<br>
                      CNPJ/MF nº: 07.278.350/0001-63
                      </p>
                      <p>
                        2 - MODALIDADE DA PROMOÇÃO:<br>
                        Assemelhado a Concurso
                      </p>
                      <p>
                        3 - ÁREA DE ABRANGÊNCIA:<br>
                        Todo o território nacional.
                      <p>
                      <p>
                        4 - PERÍODO DA PROMOÇÃO:<br>
                        01/03/2024 a 29/06/2024
                      <p>
                      <p>
                        5 - PERÍODO DE PARTICIPAÇÃO:<br>
                        01/03/2024 a 28/06/2024
                      <p>
                      <p>
                        6 - CRITÉRIO DE PARTICIPAÇÃO:<br>
                        Promoção "Show de Prêmios do Baú"
                      </p>
                      <p>
                      Para participar da promoção o interessado deverá efetuar a compra de um Carnê do Baú da Felicidade Jequiti, efetuar em dia o pagamento
                      das parcelas, preencher o cupom e depositá-lo nas urnas localizadas nos pontos de comercialização do Carnê.
                      </p>
                      <p>
                      Os cupons retirados nos dias dos sorteios voltarão à urna. <br/> O período para envio dos respectivos cupons a partir do dia 01/03/2024 até 28/06/2024.
                      </p>
       `,
  },

]