/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Divider,
  InputAdornment,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import EventIcon from "@mui/icons-material/Event";
import ContainerBox from "../../components/ContainerBox";
import {
  formatDateToYYYYMMDD,
  formatPastedDate,
  maskEmail,
  useIsMobile,
  validateCpf,
} from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { ValidaCliente } from "../../context/AuthProvider/util";
import { useAuth } from "../../context/AuthProvider/useAuth";
import {
  ContentValidate,
  StepValidate,
  StyledListValidate,
} from "../CadastrarSenha/CadastrarSenha.styled";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarContainer } from "./RecuperarSenha.styled";

const RecuperarSenha = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(["", "", "", "", ""]);
  const [title, setTitle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [context, setContext] = useState(0);
  const [formData, setFormData] = useState({
    cpf: "",
    birthDate: "",
    senha: "",
    confirmSenha: "",
    showPassword: false,
    showPasswordConfirm: false,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorSenhaConfirm, setErrorSenhaConfirm] = useState(false);
  const [errorBirthDate, setErrorBirthDate] = useState(false);
  const [errorCpf, setErrorCpf] = useState(false);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [errorSenha, setErrorSenha] = useState(false);
  const validatePasswords = useCallback(() => {
    return (
      formData.senha === "" ||
      formData.confirmSenha === "" ||
      formData.senha !== formData.confirmSenha ||
      errorSenha ||
      errorSenhaConfirm
    );
  }, [formData.senha, formData.confirmSenha, errorSenha, errorSenhaConfirm]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState<"success" | "error" | null>(
    null
  );
  const [alertMessage, setAlertMessage] = useState("");

  const InputValidate = useCallback(() => {
    return (
      formData.cpf === "" ||
      formData.birthDate === "" ||
      errorBirthDate ||
      errorCpf
    );
  }, [formData.cpf, formData.birthDate, errorBirthDate, errorCpf]);

  const [isDisabled, setIsDisabled] = useState(validatePasswords());
  const [disabled, setDisabled] = useState(InputValidate());
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [data, setData] = useState<any>(null);
  const [pinValidationInProgress, setPinValidationInProgress] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [senha, setSenha] = useState("");
  const [confirmSenha, setConfirmSenha] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [token, setToken] = useState<any>(null);
  const auth = useAuth();
  const [timer, setTimer] = useState(360); // 6 minutes in seconds
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [expired, setExpired] = useState(false);
  const [dataEmail, setDataEmail] = useState<any>(null);
  const [fieldType, setFieldType] = useState("text");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (timer > 0 && !buttonDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 && !buttonDisabled) {
      setButtonDisabled(true);
      setExpired(true);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer, buttonDisabled]);

  const startTimer = () => {
    setTimer(360);
    setButtonDisabled(false);
  };

  const clearFields = () => {
    setValues(["", "", "", "", ""]);
    setExpired(false);
    setButtonDisabled(false);
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleClose = () => {
    setOpen(false);
    handleContext(4);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate("/login");
  };

  const handleContext = (value: number) => {
    setContext(value);
  };

  useEffect(() => {
    setIsDisabled(validatePasswords());
    setDisabled(InputValidate());
  }, [
    formData.senha,
    formData.confirmSenha,
    formData.cpf,
    formData.birthDate,
    errorSenha,
    errorSenhaConfirm,
    errorBirthDate,
    errorCpf,
    validatePasswords,
    InputValidate,
  ]);

  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    value = value.replace(/[^0-9]/g, "");

    if (value.length > 2 && value.length <= 4) {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }
    if (value.length > 4) {
      value =
        value.slice(0, 2) + "/" + value.slice(2, 4) + "/" + value.slice(4, 8);
    }

    const isValid = isValidDate(value);
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrorBirthDate(!isValid);
  };

  const isValidDate = (dateStr: string) => {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!regex.test(dateStr)) return false;

    const parts = dateStr.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    const date = new Date(year, month, day);
    return date && date.getMonth() === month;
  };

  const handleDateSelect = (date: Date | null) => {
    if (date) {
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${date.getFullYear()}`;
      if (isValidDate(formattedDate)) {
        setFormData({ ...formData, birthDate: formattedDate });
      }
    } else {
      setFormData({ ...formData, birthDate: "" });
    }
  };

  const handleCPFChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cpfValue = e.target.value.replace(/[^\d]/g, "");
    if (validateCpf(cpfValue)) {
      const formattedCpf = cpfValue
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      setFormData({ ...formData, cpf: formattedCpf });
      setErrorCpf(false);
    } else {
      setFormData({ ...formData, cpf: cpfValue });
      setErrorCpf(true);
    }
  };

  // No `toggleCalendar`, remova a restrição que impede a abertura quando o campo está vazio
  const toggleCalendar = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleInputChange = async (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value.replace(/[^\d()-]/g, "");

    if (newValue.length === 1) {
      const newValues = [...values];
      newValues[index] = newValue;

      setValues(newValues);

      if (index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
    const newValues = [...values];
    newValues[index] = event.target.value;
    setValues(newValues);
  };

  const handleKeyDown = (
    index: number,
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace") {
      if (values[index] === "" && index > 0) {
        inputRefs.current[index - 1]?.focus();
      } else {
        const newValues = [...values];
        newValues[index] = "";
        setValues(newValues);
      }
    }

    if (event.key === "Delete") {
      if (index < 5) {
        const newValues = [...values];
        newValues[index] = "";
        setValues(newValues);
        inputRefs.current[index + 1]?.focus();
      } else {
        const newValues = [...values];
        newValues[index] = "";
        setValues(newValues);
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text/plain");
    const newValues = [...values];

    for (let i = 0; i < 5; i++) {
      if (pastedData[i]) {
        newValues[i] = pastedData[i];
      } else {
        break;
      }
    }

    setValues(newValues);
  };

  const handlePasteDate = (event: any) => {
    event.preventDefault();
    const cursorPosition = inputRef.current!.selectionStart;
    const pastedValue = event.clipboardData.getData("Text");
    const formattedValue = formatPastedDate(pastedValue, cursorPosition);
    setFormData({
      ...formData,
      birthDate: formattedValue,
    });
  };

  const handleInputPassword = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    const senhaValida =
      value.length >= 8 &&
      /[A-Z]/.test(value) &&
      /\d/.test(value) &&
      /[!@#$%^&*(),.?":{}|<>]/.test(value);

    setErrorSenha(!senhaValida);

    const criteriosAtendidos = [
      value.length >= 8,
      /[A-Z]/.test(value),
      /\d/.test(value),
      /[!@#$%^&*(),.?":{}|<>]/.test(value),
    ].filter(Boolean).length;

    const steps = document.querySelectorAll<HTMLElement>(".step-validate");
    steps.forEach((step, index) => {
      step.style.background =
        index < criteriosAtendidos ? "#93b749" : "#D9D9D9";
    });

    const passwordsNotMatch = value !== formData.confirmSenha;
    setIsDisabled(!senhaValida || passwordsNotMatch);
  };

  const handleClickShowPassword = () => {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  };

  const handleClickShowPasswordConfirm = () => {
    setFormData({
      ...formData,
      showPasswordConfirm: !formData.showPasswordConfirm,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const valCpf = formData.cpf.replace(/\D/g, "");

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const cpfFormatted = valCpf.replace(/[^\d]/g, "");
      console.log("formData", formData);
      const formattedBirthDate = formatDateToYYYYMMDD(formData.birthDate);
      const response: any = await ValidaCliente(
        cpfFormatted,
        formattedBirthDate
      );
      const data = response.cliente;
      const newData = {
        nome: data.nome,
        sobrenome: data.sobrenome,
        nascimento: formattedBirthDate,
        contatos: [
          { tipo: 1, valor: data.contatos[0]?.valor || "" },
          { tipo: 2, valor: data.contatos[1]?.valor || "" },
        ],
        documentos: [{ tipo: 1, valor: data.documentos[0]?.valor || "" }],
      };
      setPhone(newData.contatos[1]?.valor || "");
      setEmail(newData.contatos[0]?.valor || "");
      setData(newData);
      handleContext(1);
    } catch (error: any) {
      setAlertMessage(error);
      setAlertStatus("error");
      setAlertOpen(true);
    }
  };

  const handleSendCode = async (e: any) => {
    e.preventDefault();
    try {
      await auth?.verifyPhone(data);
      handleContext(2);
      setOpen(true);
    } catch (error: any) {
      setAlertMessage(error.response.data.error);
      setAlertStatus("error");
      setAlertOpen(true);
    }
  };

  const validEmail = async () => {
    try {
      const request = await auth?.verifyEmail(email);
      handleDataValidEmail(request);
      return request;
    } catch (error: any) {
      throw error;
    }
  };

  const handleDataValidEmail = (emailValid: any) => {
    setDataEmail({ existeEmail: emailValid });
  };

  const sendEmail = async (cpf: any, dataNasc: any, email: any) => {
    try {
      await auth?.enviaEmail(cpf, dataNasc, email);
    } catch (error: any) {
      throw error;
    }
  };

  const handleSendEmail = async (e: any) => {
    e.preventDefault();
    try {
      const isValidEmail = await validEmail();
      const dataNascimento = formatDateToYYYYMMDD(formData.birthDate);
      const cpf = valCpf;
      if (isValidEmail) {
        sendEmail(cpf, dataNascimento, email);
        handleContext(3);
        setOpen(true);
      }
    } catch (error: any) {
      setAlertMessage("E-mail não encontrado");
      setAlertStatus("error");
      setAlertOpen(true);
    }
  };

  const handleVerifyPin = async (login: string, pin: string) => {
    try {
      await auth?.verifyPin(login, pin);
      setLoading(true);
      setAlertMessage("Código validado com sucesso!");
      setAlertStatus("success");
      setAlertOpen(true);
      setTimeout(() => {
        handleContext(5);
        setTitle(true);
      }, 3000);
    } catch (error: any) {
      setAlertMessage("Código inválido! Verifique o código ou envie um novo");
      setAlertStatus("error");
      setAlertOpen(true);
    }
  };

  const handleValidaSMS = async () => {
    if (!pinValidationInProgress) {
      setPinValidationInProgress(true);
      try {
        const cpfFormatted = valCpf;
        const login = cpfFormatted;
        const pin = values.join("");
        handleVerifyPin(login, pin);
      } catch (error: any) {
        setAlertMessage("Erro ao validar PIN");
        alert("error");
        setAlertOpen(true);
      } finally {
        setPinValidationInProgress(false);
      }
    }
  };

  const handleDescript = async (item: any) => {
    try {
      if (auth) {
        await auth?.descriptToken(item);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const lastThreeDigits = phone.slice(-3);

  const handlePutClient = async (data: any, token: any) => {
    try {
      if (auth) {
        await auth?.putClient(data, token);
        setLoading(false);
      }
    } catch (error: any) {
      setAlertMessage(`${error.response.data.error}`);
      alert("error");
      setAlertOpen(true);
      throw error;
    }
  };

  useEffect(() => {
    if (token) {
      handleDescript(token);
    }
  }, []);

  const handleSubmitPassword = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (senha !== confirmSenha) {
      setPasswordError(true);
      return;
    }
    try {
      const cpfFormatted = valCpf;
      const formattedBirthDate = formatDateToYYYYMMDD(formData.birthDate);
      const response = await ValidaCliente(cpfFormatted, formattedBirthDate);
      const data = response.cliente;
      const newData = {
        nome: data.nome,
        sobrenome: data.sobrenome,
        nascimento: data.nascimento,
        login: cpfFormatted,
        senha: formData.senha,
        contatos: [
          { tipo: 1, valor: data.contatos[0]?.valor || "" },
          { tipo: 2, valor: data.contatos[1]?.valor || "" },
        ],
        documentos: [{ tipo: 1, valor: data.documentos[0]?.valor || "" }],
        enderecos: data.enderecos,
      };
      const tokenUser = response["token-user"];
      setToken(tokenUser);
      handlePutClient(newData, token);
      setAlertMessage(`${data}`);
      handleOpenModal();
      setLoading(false);
    } catch (error: any) {
      setAlertMessage(`${error.response.data.error}`);
      setLoading(false);
      setAlertStatus("error");
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    if (context === 4) {
      clearFields();
      startTimer();
    }
  }, [context]);

  return (
    <ContainerBox>
      <Modal
        open={openModal}
        disableEnforceFocus
        disableAutoFocus
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "300px",
            height: "210px",
            background: "#FFFFFF",
            border: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "58px",
            }}
          >
            <div>
              <Typography
                variant="h2"
                color="#000000"
                sx={{
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                Senha alterada com sucesso!
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                onClick={handleCloseModal}
                sx={{
                  width: 60,
                  height: 40,
                  backgroundColor: "#F08C10",
                  marginTop: "20px",
                  fontSize: "14px",
                  color: "#FFFFFF",
                  background: "#F08C10",
                  "&: hover": {
                    background: "#F08C10",
                  },
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      {context === 2 && (
        <Modal
          open={open}
          disableEnforceFocus
          disableAutoFocus
          sx={{
            display: "flex",
            p: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "300px",
              height: "400x",
              background: "#FFFFFF",
              borderRadius: 1,
              color: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: "40px 30px",
              }}
            >
              <div>
                <Typography
                  variant="subtitle1"
                  color="#000000"
                  sx={{
                    fontWeight: 400,
                  }}
                >
                  Código enviado!
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#000000"
                  sx={{
                    fontWeight: 400,
                    margin: "20px 0",
                  }}
                >
                  Foi enviado um código para o seu celular de final{" "}
                  <strong>{lastThreeDigits}</strong>
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    width: 60,
                    height: 40,
                    backgroundColor: "#F08C10",
                    fontSize: "14px",
                    color: "#FFFFFF",
                    background: "#F08C10",
                    marginTop: 2,
                    "&: hover": {
                      background: "#F08C10",
                    },
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      {context === 3 && (
        <Modal
          open={open}
          disableEnforceFocus
          disableAutoFocus
          sx={{
            display: "flex",
            p: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "300px",
              height: "400x",
              background: "#FFFFFF",
              borderRadius: 1,
              color: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: "40px 30px",
              }}
            >
              <div>
                <Typography
                  variant="subtitle1"
                  color="#000000"
                  sx={{
                    fontWeight: 400,
                  }}
                >
                  E-mail enviado!
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#000000"
                  sx={{
                    fontWeight: 400,
                    margin: "20px 0",
                  }}
                >
                  As instruções para criar uma nova senha foram enviadas para o
                  seu e-mail.
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#000000"
                  sx={{
                    fontWeight: 400,
                    margin: "20px 0px",
                  }}
                >
                  <strong>{maskEmail(email)}</strong>
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#000000"
                  sx={{
                    fontWeight: 400,
                  }}
                >
                  Não se esqueça de verificar a caixa de Spam.
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  onClick={handleCloseModal}
                  sx={{
                    width: 60,
                    height: 40,
                    backgroundColor: "#F08C10",
                    fontSize: "14px",
                    color: "#FFFFFF",
                    background: "#F08C10",
                    marginTop: 2,
                    "&: hover": {
                      background: "#F08C10",
                    },
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      <Grid
        container
        sx={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "center" },
            margin: `${isMobile ? 0 : "120px 0px 20px 0px"}`,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: `${isMobile ? "300px" : "454px"}`,
            }}
          >
            <Typography
              variant="h1"
              sx={{
                width: "100%",
                marginBottom: "12px !important",
              }}
            >
              {title ? "Criar Senha" : "Esqueci minha senha"}
            </Typography>
            {context === 0 && (
              <div>
                <Typography
                  variant="subtitle1"
                  color="#000000"
                  sx={{
                    width: "100%",
                  }}
                >
                  Informe seu CPF e data de nascimento cadastrados:
                </Typography>
                <form onSubmit={handleSubmit}>
                  <div>
                    <Typography
                      variant="subtitle2"
                      color="#000000"
                      sx={{
                        textTransform: "uppercase",
                        marginTop: "20px",
                        fontSize: "10px",
                        fontWeight: 700,
                      }}
                    >
                      CPF
                    </Typography>
                    <TextField
                      sx={{
                        boxSizing: "border-box",
                        background: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      name="cpf"
                      value={formData.cpf
                        .replace(/[^\d.-]/g, "")
                        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
                        .slice(0, 14)}
                      onChange={handleCPFChange}
                      placeholder="000.000.000-00"
                      required
                      error={errorCpf}
                      helperText={errorCpf ? "CPF inválido" : null}
                      InputProps={{
                        endAdornment: true ? null : (
                          <CheckOutlinedIcon
                            sx={{ color: "orange", marginRight: "8px" }}
                          />
                        ),
                        style: {
                          height: "48px",
                        },
                      }}
                    />
                  </div>
                  <div>
                    <Typography
                      variant="subtitle2"
                      color="#000000"
                      sx={{
                        textTransform: "uppercase",
                        marginTop: "20px",
                        fontSize: "10px",
                        fontWeight: 700,
                      }}
                    >
                      data de nascimento*
                    </Typography>
                    <TextField
                      name="birthDate"
                      placeholder="DD/MM/AAAA"
                      value={formData.birthDate}
                      onChange={handleDateChange}
                      error={errorBirthDate}
                      inputRef={inputRef}
                      required
                      helperText={
                        errorBirthDate
                          ? "Informe uma data de nascimento válida e anterior à data atual"
                          : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={toggleCalendar}
                              aria-label="toggle date picker"
                            >
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                    {isOpen && (
                      <CalendarContainer>
                        <DatePicker
                          selected={
                            formData.birthDate &&
                            isValidDate(formData.birthDate)
                              ? new Date(
                                  formData.birthDate
                                    .split("/")
                                    .reverse()
                                    .join("-") + "T00:00:00"
                                )
                              : null
                          }
                          onChange={handleDateSelect}
                          inline
                          calendarClassName="custom-calendar"
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </CalendarContainer>
                    )}
                    <Button
                      disabled={disabled}
                      type="submit"
                      sx={{
                        width: "100%",
                        height: "48px",
                        margin: "60px 0 0 0",
                        fontSize: "14px",
                        color: "#FFFF",
                        background: `${disabled ? "#D1D1D1" : "#F08C10"}`,
                        "&: hover": {
                          background: `${disabled ? "#D1D1D1" : "#F08C10"}`,
                        },
                      }}
                    >
                      enviar
                    </Button>
                  </div>
                </form>
              </div>
            )}
            {context === 1 && (
              <div>
                <Typography
                  variant="subtitle1"
                  color="#000000"
                  sx={{
                    width: "100%",
                    marginBottom: 1,
                  }}
                >
                  Escolha uma das opções abaixo para receber as instruções de
                  como cadastrar uma nova senha:
                </Typography>
                <form>
                  <List>
                    <Divider component="li" />
                    <ListItemButton onClick={handleSendCode}>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography variant="subtitle1" color="#636363">
                              <ChatBubbleOutlineOutlinedIcon /> SMS
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              variant="subtitle1"
                              color="rgba(0, 0, 0, 0.7)"
                            >
                              Enviaremos uma validação para o celular com final:
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="#000000"
                              sx={{
                                fontWeight: 700,
                              }}
                            >
                              {lastThreeDigits}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <ArrowForwardIosOutlinedIcon sx={{ color: "#636363" }} />
                    </ListItemButton>
                    <Divider component="li" />
                    <ListItemButton onClick={handleSendEmail}>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography variant="subtitle1" color="#636363">
                              <EmailOutlinedIcon /> E-mail
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              variant="subtitle1"
                              color="rgba(0, 0, 0, 0.7)"
                            >
                              Enviaremos as instruções para o e-mail:
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="#000000"
                              sx={{
                                fontWeight: 700,
                              }}
                            >
                              {maskEmail(email)}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <ArrowForwardIosOutlinedIcon sx={{ color: "#636363" }} />
                    </ListItemButton>
                    <Divider component="li" />
                  </List>
                  <Typography
                    variant="subtitle1"
                    color="#000000"
                    sx={{
                      textAlign: "justify",
                      fontSize: "12px",
                      margin: "50px 0",
                    }}
                  >
                    Caso não tenha mais acesso{" "}
                    <strong>
                      ao endereço de e-mail ou telefone associado à sua conta do
                      Baú
                    </strong>{" "}
                    entre em contato com nosso atendimento pelo{" "}
                    <strong>
                      WhatsApp Oficial do Baú: 11 95302 0985 (opção
                      Cadastro/Reset de Senha)
                    </strong>
                    , siga as instruções de segurança e receba uma nova senha.
                  </Typography>
                </form>
              </div>
            )}
            {context === 4 && (
              <div>
                <Typography
                  variant="subtitle1"
                  color="#000000"
                  sx={{
                    width: "100%",
                  }}
                >
                  Insira abaixo o código recebido por {`"forma escolhida"`}{" "}
                  contendo 05 dígitos para validação da sua conta:
                </Typography>
                <form>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      margin: "60px 0",
                    }}
                  >
                    {values.map((value, index) => (
                      <TextField
                        variant="standard"
                        key={index}
                        inputRef={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        margin="normal"
                        value={value}
                        sx={{
                          width: "30px",
                          margin: "0 10px",
                        }}
                        inputProps={{
                          maxLength: 1,
                          style: {
                            textAlign: "center",
                            fontSize: "24px",
                            fontWeight: 700,
                            color: "#1E1E1E",
                          },
                        }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleInputChange(index, event)
                        }
                        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) =>
                          handleKeyDown(index, event)
                        }
                        onPaste={handlePaste}
                      />
                    ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "20px 0px",
                    }}
                  >
                    {timer > 0 && !expired && (
                      <p>
                        Seu código expira em: {Math.floor(timer / 60)}:
                        {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                      </p>
                    )}
                    {expired && <p>Código expirado! Tente novamente.</p>}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      disabled={
                        !values.every((value) => value !== "") || buttonDisabled
                      }
                      onClick={handleValidaSMS}
                      sx={{
                        width: "330px",
                        height: "48px",
                        margin: "30px 0",
                        fontSize: "14px",
                        color: "#FFFFFF",
                        background: `${
                          !values.every((value) => value !== "") ||
                          buttonDisabled
                            ? "#D1D1D1"
                            : "#F08C10"
                        }`,
                        "&: hover": {
                          background: `${
                            !values.every((value) => value !== "") ||
                            buttonDisabled
                              ? "#D1D1D1"
                              : "#F08C10"
                          }`,
                        },
                      }}
                    >
                      {loading ? (
                        <CircularProgress sx={{ color: "#FFFFFF" }} />
                      ) : (
                        "RECUPERAR SENHA"
                      )}
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={handleSendCode}
                      sx={{
                        margin: 0,
                        width: "330px",
                        height: "48px",
                        fontSize: "14px",
                        color: "#BDBDBD",
                        border: "1px solid #BDBDBD",
                        background: "#FFFFFF",
                        "&: hover": {
                          background: "#FFFFFF",
                        },
                      }}
                    >
                      REENVIAR CÓDIGO
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      component="button"
                      onClick={() => handleContext(1)}
                      color="#F08C10"
                      sx={{
                        fontWeight: 700,
                        margin: "20px 0",
                        border: 0,
                        background: "#FFFFFF",
                      }}
                    >
                      Enviar por outro meio
                    </Typography>
                  </div>
                </form>
                <div></div>
              </div>
            )}
            {context === 5 && (
              <div>
                <Typography
                  variant="subtitle1"
                  color="#000000"
                  sx={{
                    width: "100%",
                  }}
                >
                  Cadastre abaixo uma nova senha, seguindo as recomendações de
                  segurança:
                </Typography>
                <form onSubmit={handleSubmitPassword}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="#939598"
                          sx={{
                            marginTop: "20px",
                            fontSize: "10px",
                            fontWeight: 700,
                          }}
                        >
                          Nova senha
                        </Typography>
                      </div>
                      <TextField
                        sx={{
                          boxSizing: "border-box",
                          background: "rgba(255, 255, 255, 0.1)",
                          width: `${isMobile ? "300px" : "330px"}`,
                        }}
                        name="senha"
                        type={formData.showPassword ? "text" : "password"}
                        value={formData.senha}
                        required
                        onChange={handleInputPassword}
                        placeholder="Informe sua senha"
                        error={errorSenha}
                        inputProps={{
                          maxLength: 30,
                        }}
                        InputProps={{
                          style: {
                            height: "48px",
                            borderColor: "#BDBDBD",
                            borderRadius: 0,
                            fontSize: "14px",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {formData.showPassword ? (
                                  <RemoveRedEyeOutlinedIcon />
                                ) : (
                                  <VisibilityOffOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <ContentValidate>
                    <Typography
                      variant="body1"
                      sx={{
                        marginTop: "22px",
                        padding: "0px 5px",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#636363",
                      }}
                    >
                      Veja o que sua senha precisa conter:
                    </Typography>
                    <StyledListValidate>
                      <li
                        style={{
                          color: /[A-Z]/.test(formData.senha)
                            ? "#93b749"
                            : "#636363",
                        }}
                      >
                        conter letras maiúsculas (pelo menos uma)
                      </li>
                      <li
                        style={{
                          color: /\d/.test(formData.senha)
                            ? "#93b749"
                            : "#636363",
                        }}
                      >
                        conter número (pelo menos um)
                      </li>
                      <li
                        style={{
                          color: /[!@#$%^&*(),.?":{}|<>]/.test(formData.senha)
                            ? "#93b749"
                            : "#636363",
                        }}
                      >
                        conter caracteres especiais (pelo menos um)
                      </li>
                      <li
                        style={{
                          color:
                            formData.senha.length >= 8 ? "#93b749" : "#636363",
                        }}
                      >
                        conter mínimo 8 caracteres
                      </li>
                    </StyledListValidate>
                    <div
                      style={{
                        justifyContent: "space-around",
                        display: "flex",
                      }}
                    >
                      <StepValidate
                        className={errorSenha ? "step-validate" : ""}
                      />
                      <StepValidate
                        className={errorSenha ? "step-validate" : ""}
                      />
                      <StepValidate
                        className={errorSenha ? "step-validate" : ""}
                      />
                      <StepValidate
                        className={errorSenha ? "step-validate" : ""}
                      />
                    </div>
                  </ContentValidate>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="#939598"
                          sx={{
                            marginTop: "20px",
                            fontSize: "10px",
                            fontWeight: 700,
                          }}
                        >
                          Confirme sua nova senha
                        </Typography>
                      </div>
                      <TextField
                        sx={{
                          boxSizing: "border-box",
                          background: "rgba(255, 255, 255, 0.1)",
                          width: `${isMobile ? "300px" : "330px"}`,
                        }}
                        name="confirmSenha"
                        type={
                          formData.showPasswordConfirm ? "text" : "password"
                        }
                        value={formData.confirmSenha}
                        required
                        onChange={handleInputPassword}
                        placeholder="Confirme sua senha"
                        error={errorSenhaConfirm}
                        inputProps={{
                          maxLength: 30,
                        }}
                        InputProps={{
                          style: {
                            height: "48px",
                            borderColor: "#BDBDBD",
                            borderRadius: 0,
                            fontSize: "14px",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPasswordConfirm}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {formData.showPassword ? (
                                  <RemoveRedEyeOutlinedIcon />
                                ) : (
                                  <VisibilityOffOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography
                        variant="subtitle2"
                        color="#d32f2f"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        {formData.senha !== formData.confirmSenha
                          ? "Senhas divergentes"
                          : ""}
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={handleOpenModal}
                      disabled={isDisabled}
                      type="submit"
                      sx={{
                        width: `${isMobile ? "300px" : "330px"}`,
                        height: "48px",
                        margin: "30px 0",
                        fontSize: "14px",
                        color: "#FFFFFF",
                        background: `${isDisabled ? "#D1D1D1" : "#F08C10"}`,
                        "&: hover": {
                          background: `${isDisabled ? "#D1D1D1" : "#F08C10"}`,
                        },
                      }}
                    >
                      Confirmar
                    </Button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={alertOpen}
        autoHideDuration={5000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setAlertOpen(false)}
          severity={alertStatus || "info"}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </ContainerBox>
  );
};

export default RecuperarSenha;
