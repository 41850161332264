import React from "react";

const TextoBau = () => {
  return (
    <div>
      <p>O carnê do Baú da Felicidade é Jequiti.</p>
      <p>
        Você paga R$ 240,00, recebe até R$ 264,00 em perfumes e/ou cosméticos da
        Jequiti e ainda concorre a prêmios em sorteios diários.
      </p>
      <p>
        A Jequiti em parceria com o Grupo Silvio Santos, trouxe para você um
        produto conhecido em todo o Brasil:{" "}
        <strong>O CARNE DO BAÚ DA FELICIDADE!</strong> Com enorme credibilidade
        entre os brasileiros e que já premiou milhares de pessoas.
      </p>
      <p>
        O Carnê do Baú da Felicidade Jequiti é disponibilizado por meio das mais
        de 200 mil consultoras e consultores da Jequiti, nas casas Lotéricas, no
        site Jequiti | Baú da Felicidade, pelo telefone 0800 545 2121, nas
        agências dos Correios e no WhatsApp (11) 95302 0985.
      </p>
      <p>
        A cada prestação de R$20,00 paga, o cliente terá direito a preencher e
        enviar dois cupons para concorrer a sorteios em nossos programas de TV,
        que serão exibidos diariamente, proporcionando aos participantes prêmios
        incríveis.
      </p>
      <ol>
        <li>Frete grátis a partir da 10º parcela.</li>
        <br />
        <li>Não existe multa ou juros sobre as parcelas atrasadas.</li>
        <br />
        <li>
          Os pagamentos das parcelas do carnê do Baú podem ser realizados nas
          casas Lotéricas, agências dos Correios, no site{" "}
          <a href="https://bau.jequiti.com.br/">www.bau.jequiti.com.br</a> ou
          0800 545 2121 com seu cartão de crédito.
        </li>
        <br />
        <li>
          Você pode adquirir com as(os) Consultoras(res) da Jequiti, nas casas
          Lotéricas, agências dos Correios, no site{" "}
          <a href="https://bau.jequiti.com.br/">www.bau.jequiti.com.br</a> pelo
          telefone 0800 545 2121 ou WhatsApp (11) 97696 3019.
        </li>
        <br />
        <li>
          Para o resgate dos seus produtos, faça diretamente pelo site
          bau.jequiti.com.br ou ligue gratuitamente para o telefone 0800 545
          2121.
        </li>
        <br />
      </ol>
      <p>
        <strong>Importante:</strong>
      </p>
      <p>
        Assim que você estiver com o seu carnê em mãos faça imediatamente o
        cadastramento de seus dados aqui pelo site bau.jequiti.com.br, pelo
        telefone 0800 545 2121 ou preenchendo o cadastro no carnê e enviando
        pela Caixa Postal: 05909-960, porque se você for sorteado para
        participar dos Programas de TV no SBT, seu nome só será validado se
        estiver cadastrado em nosso sistema e com as suas parcelas em dia.
      </p>
      <p>
        Se você já tem outros carnês, basta entrar no site bau.jequiti.com.br ou
        pelo telefone 0800 545 2121 e “vincular” este novo carnê ao seu CPF. No
        caso dos carnês solicitados pelo site, telefone, WhatsApp ou o enviado
        na caixa de resgate, não é necessário fazer o cadastro, pois para o
        envio já vinculamos o carnê ao cliente.
      </p>
    </div>
  );
};

export default TextoBau;
