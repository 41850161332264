
export const programas = [
  {
    label: 'Giro do Baú',
    image: `${process.env.REACT_APP_IMAGE_SERVER_URL}giro_bau.png`,
    title: 'Giro do Baú',
    description: `
    Todos os domingos, um cliente é sorteado e terá a chance de ganhar R$ 20.000,00, R$ 30.000,00 ou até R$ 50.000,00 no Giro do Baú!<br />
    O prêmio será determinado onde a bolinha parar após o giro, realizado pelo Luiz Ricardo e um convidado especial.<br />
    O programa será exibido semanalmente no SBT<br />
    <p><span><a href='${process.env.REACT_APP_LINK_KITS}carne-do-bau-da-felicidade-jequiti-2022/p' target="_blank">Compre</span></a> o seu carnê do Baú, mantenha as parcelas em dia e boa sorte!</p>
    Para mais informações sobre a promoção, confira o regulamento`,
    link: 'Veja o regulamento',
    id: '1',
    middleImg: 'true'
  },
  {
    label: 'Pião da Felicidade',
    image: `${process.env.REACT_APP_IMAGE_SERVER_URL}Logo - Pião da Felicidade.png`,
    title: 'Pião da Felicidade',
    description: `
    Todos os domingos, 3 clientes disputarão o prêmio máximo de R$ 50 mil.<br />
    Cada cliente rodará 1 vez o Pião da Felicidade e aquele que tirar o maior número ganhará o prêmio máximo. Os outros 2 clientes também ganharão um prêmio no valor de R$ 5 mil.<br />
    O programa será exibido semanalmente no SBT<br />
    <p><span><a href='${process.env.REACT_APP_LINK_KITS}carne-do-bau-da-felicidade-jequiti-2022/p' target="_blank">Compre</span></a> o seu carnê do Baú, mantenha as parcelas em dia e boa sorte!</p>
    Para mais informações sobre a promoção, confira o regulamento`,
    link: 'Veja o regulamento',
    id: '2',
    middleImg: 'true'
  },
  {
    label: 'MEGA SORTEIO',
    image: `${process.env.REACT_APP_IMAGE_SERVER_URL}mega_sorteio.png`,
    title: 'MEGA SORTEIO',
    description: `
    Três clientes terão a oportunidade de participar de um emocionante sorteio, e concorrer ao prêmio de até R$ 1 Milhão!<br />
    Cada participante jogará três bolinhas em uma canaleta numerada de 1 a 9. Quem acumular a maior pontuação será o grande vencedor!<br />
    <p>Não perca essa chance! <span><a href='${process.env.REACT_APP_LINK_KITS}carne-do-bau-da-felicidade-jequiti-2022/p' target="_blank">Compre</span></a> o seu carnê do Baú, mantenha as parcelas em dia e boa sorte!</p>
    Para mais informações sobre a promoção, confira o regulamento`,
    link: 'Veja o regulamento',
    id: '3',
    middleImg: 'true'
  },
  {
    label: 'Show de Prêmios do Baú',
    image: `${process.env.REACT_APP_IMAGE_SERVER_URL}logo-show-premios.svg`,
    title: 'Show de Prêmios do Baú',
    description: `
    O Show de Prêmios é a nova promoção do Baú da Felicidade.<br />
    Você concorre a sorteios de eletroeletrônicos de segunda à sábado e aos domingos você pode ganhar até R$ 45 mil, que podem ser cumulativos até sair um ganhador.<br />
    O programa será exibido diariamente no SBT.<br />
    <p><span><a href='${process.env.REACT_APP_LINK_KITS}carne-do-bau-da-felicidade-jequiti-2022/p' target="_blank">Compre</span></a> o seu carnê do Baú, mantenha as parcelas em dia e boa sorte!</p>
    Para mais informações sobre a promoção, confira o regulamento`,
    link: 'Veja o regulamento',
    id: '4',
    middleImg: 'true'
  },
  {
    label: 'Roleta Da Felicidade',
    image: `${process.env.REACT_APP_IMAGE_SERVER_URL}img-roleta.svg`,
    title: 'Roleta da Felicidade',
    description: `
                  Você tem a chance de ganhar até R$12.000,00 todos os dias com o programa Roleta da Felicidade. São 3 sorteios diários de até 36.000,00 em prêmios de acordo com os giros da roleta. O Baú reservou mais de 1 MILHÃO todo mês.
                  Os valores serão pagos de acordo com a mensalidade da parcela sorteada multiplicados por R$1.000,00 (hum mil reais).<br />
                  Exemplos:
                  <ul className="example-list">
                    <li>Se o número da roleta for o 5. O cliente sorteado ganha R$5.000,00 (cinco mil reais).</li>
                    <li>Se o número da roleta for o 10. O cliente sorteado ganha R$10.000,00 (dez mil reais).</li>
                    <li>Se o número da roleta for o 12. O cliente sorteado ganha R$12.000,00 (doze e cinco mil reais).</li>
                  </ul>
                  Pague as parcelas e envie seus cupons. Assim, você tem mais chances de ganhar.`,
    id: '5',
    middleImg: 'true'
  },
  {
    label: 'Cupom Premiado do Baú',
    image: `${process.env.REACT_APP_IMAGE_SERVER_URL}cupom.svg`,
    title: 'Cupom Premiado do Baú',
    description: `
    <p>
      O programa Cupom Premiado do BAÚ da Felicidade com prêmios em dinheiro diariamente.
    </p>
    <p>
      Você tem a chance de ganhar de R$1.000,00 até R$12.000,00 todos os dias<br> com o programa Cupom
      premiado do BAÚ da Felicidade Jequiti. Serão 3<br> sorteios diários.
    </p>
    <p>
      Os valores serão pagos de acordo com a mensalidade da parcela sorteada<br>
      multiplicados por R$1.000,00 (hum mil reais). Exemplo: Cupom sorteado da <br>
      parcela número 6. O cliente sorteado ganha R$6.000,00 (seis mil reais).<br>
      Pague as parcelas e envie seus cupons. Assim, você tem mais chances de ganhar.
    </p>
`,
    id: '6',
    link: '',
    middleImg: 'true'
  },
  {
    label: 'Para Ganhar',
    image: `${process.env.REACT_APP_IMAGE_SERVER_URL}paraganhar.svg`,
    title: 'Pra ganhar é só rodar',
    description: `
    <p>
      No programa "Pra Ganhar É Só Rodar", o participante sorteado vai até o palco e roda cinco piões com as fotos dos artistas do SBT. O prêmio é definido de acordo com as combinações das fotos e pode valer até UM MILHÃO DE REAIS.
    </p>
    <ul>
      <li>1 par de artistas – ganha 5 mil reais.</li>
      <li>2 pares de artistas – ganha 10 mil reais.</li>
      <li>3 artistas iguais – ganha 15 mil reais.</li>
      <li>3 artistas iguais e 1 par de artistas – ganha 20 mil reais.</li>
      <li>4 artistas iguais – ganha 50 mil reais.</li>
      <li>5 artistas diferentes – ganha 250 mil reais.</li>
      <li>5 artistas iguais – ganha 1 milhão de reais.</li>
    </ul>
    <p>
      Para participar basta enviar para o SBT o cupom que vem no carnê do "Baú da Felicidade" e manter as mensalidades em dia.
    </p>
`,
    id: '7',
    link: '',
    middleImg: 'true'
  },

  {
    label: 'Bolsa Família',
    image: `${process.env.REACT_APP_IMAGE_SERVER_URL}logo-bolsa-familia.svg`,
    title: 'Bolsa Família',
    description: `
                  <p>
                    Um dos quadros de maior sucesso lançados pelo Programa de Silvio Santos é<br>
                    o Bolsa Família SBT com premiação de até UM MILHÃO DE REAIS.<br>
                    Cada pergunta que uma família acertar, ela terá direito a escolher umas das 40<br>
                    bolsas espalhadas pelo palco, as quais, trarão bilhetes informando os prêmios<br>
                    ganhos, que poderão ser desde de uma grande quantia de dinheiro, até <br>
                    mesmo aparelhos eletrônicos.<br>
                    Tudo vai depender da sorte que cada família tiver, ao escolher alguma bolsa <br>
                    espalhada pelo palco, o que vai deixar essa nova atração ainda mais divertida e<br>
                    emocionante para as famílias que irão participar dela.
                  </p>
    `,
    id: '8',
    link: '',
    middleImg: 'true'
  },
];