import { FC, useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Modal,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TextField,
  Grid,
  IconButton,
  InputBase,
  Alert,
  Snackbar,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  formatarValorEmReais,
  formatDate,
  downloadBase64File,
  useIsMobile,
} from "../../utils/utils";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { CartContext } from "../../context/CartProvider";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useCart } from "../../context/CartProvider/useCart";
import { alpha, styled } from "@mui/material/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SkeletonParcelas from "../Skeleton/SkeletonParcelas";
import { useHandleParcela } from "../../context/ContextParcelas/userContextParcelas";
import { CustomTooltip } from "./Parcelas.styled";
import { Toast } from "../Toast";
// import styled from "styled-components";

const Input = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1),
  },
  "& .MuiTypography-root .MuiFormLabel-root .MuiInputLabel-root": {
    fontFamily: "Red Hat Display, sans-serif",
  },
  "& .MuiInputBase-input": {
    borderRadius: 6,
    position: "relative",
    border: "2px solid #E5E5E5",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 12,
    textAlign: "center",
    background: "rgba(239, 216, 138, 0.49)",
    padding: "10px 12px",
    marginBottom: theme.spacing(2),
    heigth: "50px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: ["Red Hat Display, sans-serif"].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
interface ParcelaData {
  id: number;
  carne: any;
  codigoBarras: string;
  codigoPix: string;
  dataVencimento: any;
  idCarne: number;
  linkCupomDigital: string;
  numero: number;
  pagamento?: {
    data: string;
    valor: number;
    localPagamento: string;
  } | null;
  statusPagamento?: {
    id: number;
    descricao: string;
    aceitaPagamento: boolean;
  };
  valor: number;
  cpfClie: string;
  codigoBarrasCarne: string;
  serie: string;
  numeroCarne: string;
  digito: string;
  aceitaPagamento: boolean;
}

type Props = {
  id: string;
  code: string;
  setView: any;
  onClickDetalhes: () => void;
};

type ColumnVisibility = {
  Parcela: boolean;
  "2ª via": boolean;
  Valor: boolean;
  status: boolean;
  "Local de Pagamento": boolean;
  "Data de Vencimento": boolean;
  "Data de Pagamento": boolean;
  Cupom: boolean;
};

const Parcelas: FC<Props> = ({ id, code, setView, onClickDetalhes }) => {
  const auth = useAuth();
  const cart = useCart();
  const navigate = useNavigate();
  const { state, setState, getDetail } = useHandleParcela();
  const { cartItems, setCartItems, updateCartFromLocalStorage, infoPayment } =
    useContext(CartContext)!;
  const { payment, setPayment } = useContext(CartContext)!;
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState<ParcelaData[]>([]);
  const [parcela, setParcela] = useState<null | any>(null);
  const [info, setInfo] = useState<null | any>(null);
  const [parcela13Valor, setParcela13Valor] = useState("");
  const [isParcela13Selected, setIsParcela13Selected] = useState(false);
  const [mostrarDetalhes, setMostrarDetalhes] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [openModalParcela, setOpenModalParcela] = useState(false);
  const handleOpenParcela = () => setOpenModalParcela(true);
  const handleCloseParcela = () => setOpenModalParcela(false);
  const [loading, setLoading] = useState(false);
  const [tokenId, setTokenId] = useState("");
  const [carneId, setCarneId] = useState("");
  const parcela13TextFieldRef = useRef<HTMLInputElement | null>(null);
  const [userValue, setUserValue] = useState<null | any>(null);
  const [copiedPix, setCopiedPix] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState<"success" | "error" | null>(
    null
  );

  const [firstRender, setFirstRender] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [consultarDetalhesAtivo, setConsultarDetalhesAtivo] = useState(false);
  const [exibirDetalhe, setExibirDetalhe] = useState(false);
  const [openToastErrorSecond, setOpenToastErrorSecond] = useState(false);
  const isMobile = useIsMobile();
  const defaultColumns: ColumnVisibility = {
    Parcela: true,
    "2ª via": false,
    Valor: true,
    status: true,
    "Local de Pagamento": false,
    "Data de Vencimento": false,
    "Data de Pagamento": false,

    Cupom: false,
  };

  const [visibleColumns, setVisibleColumns] = useState<ColumnVisibility>({
    ...defaultColumns,
  });

  useEffect(() => {
    setTokenId(id);
    setCarneId(code);
    handleParcela(code);
    const token = localStorage.getItem("token");

    if (token != null) handleDescript(token);
  }, [id, code]);

  useEffect(() => {
    const storedItems = localStorage.getItem("selectedItems");
    if (storedItems) {
      const parsedItems = JSON.parse(storedItems);
      setSelectedItems(parsedItems);
    }
  }, []);

  useEffect(() => {
    if (isParcela13Selected && parcela13TextFieldRef.current) {
      parcela13TextFieldRef.current.focus();
    }
  }, [isParcela13Selected]);

  useEffect(() => {
    const cartItemIds = cartItems.map((item: any) => item.id);

    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((item: any) => cartItemIds.includes(item.id))
    );
  }, [cartItems]);

  const handleCopyPix = () => {
    navigator.clipboard.writeText(info?.qrCode);
    setCopiedPix(true);
    setTimeout(() => {
      setCopiedPix(false);
    }, 3000);
  };

  const handleDescript = async (data: string) => {
    try {
      if (auth) {
        const request = await auth?.descriptToken(data);
        setUserValue(request);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleParcela = async (data: any) => {
    setLoading(true);
    try {
      if (auth) {
        const request = await auth?.carneParcela(data);
        setLoading(false);
        setParcela(request);
      }
    } catch (error: any) {
      setLoading(false);
      throw error;
    }
  };

  const tableTitle = () => {
    if (firstRender) {
      mostrarBotaoOcultarDetalhes();
      return "Parcelas";
    } else if (!exibirDetalhe) {
      mostrarBotaoOcultarDetalhes();
      return "Detalhes da Parcela";
    } else {
      mostrarBotaoConsultarDetalhes();
      return "Parcelas";
    }
  };

  function sanitizeLink(link: any) {
    // Remove "http//" se existir
    link = link.replace("http//", "");

    // Remove "https//" se existir
    link = link.replace("https//", "");

    // Se não começar com "http" ou "https", adiciona "https://"
    if (!link.startsWith("http://") && !link.startsWith("https://")) {
      link = `https://${link}`;
    }

    return link;
  }

  const handleDetailBody = () => {
    return (
      <TableBody>
        {parcela?.parcelas.map((item: any, index: any) => (
          <TableRow
            key={item.numero}
            sx={{
              "&.selected": {
                background: "#F08C10",
              },
              "&.pago": {
                "& td": {
                  color: "#BDBDBD",
                },
              },
            }}
            className={item.pagamento?.data ? "pago" : ""}
          >
            <TableCell padding="checkbox" sx={{ height: "45px" }}>
              {(!payment || parcela?.aceitaPagamento) && (
                <Checkbox
                  id="checkbox-select-parcela"
                  checked={
                    (selectedItems.length > 0 &&
                      selectedItems.some(
                        (selectedItem) => selectedItem.id === item.id
                      )) ||
                    (cartItems.length > 0 &&
                      cartItems.some((cartItem) => cartItem.id === item.id))
                  }
                  onChange={(event) =>
                    handleCheckboxChange(event, {
                      id: item.id,
                      carne: item.carne,
                      codigoBarras: item.codigoBarras,
                      codigoPix: item.codigoPix,
                      dataVencimento: item.dataVencimento,
                      idCarne: item.idCarne,
                      linkCupomDigital: item.linkCupomDigital,
                      numero: item.numero,
                      pagamento: item.pagamento,
                      statusPagamento: item.statusPagamento,
                      valor: item.valor,
                      cpfClie: item.cpfClie,
                      codigoBarrasCarne: item.codigoBarrasCarne,
                      serie: `${item.codigoBarrasCarne.slice(0, 3)}`,
                      numeroCarne: `${item.codigoBarrasCarne.slice(3, 9)}`,
                      digito: `${item.codigoBarrasCarne.slice(9, 10)}`,
                      aceitaPagamento: item.aceitaPagamento,
                    })
                  }
                  disabled={
                    !payment ||
                    !parcela?.aceitaPagamento ||
                    !item.statusPagamento?.aceitaPagamento ||
                    (cartItems.length > 0 &&
                      cartItems.some((cartItem) => cartItem.id === item.id))
                  }
                  sx={{
                    display: `${!item.pagamento ? "" : "none"}`,

                    "&.Mui-checked": {
                      color: "#F08C10",
                    },
                    "&.Mui-disabled": {
                      color: "#BDBDBD",
                    },
                  }}
                />
              )}
            </TableCell>
            <TableCell sx={{ padding: "8px", height: "45px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  component="p"
                  variant="subtitle1"
                  color={
                    selectedItems.length > 0 &&
                    selectedItems.some(
                      (selectedItem) => selectedItem.id === item.id
                    )
                      ? "#F08C10"
                      : item.pagamento || !payment || !parcela?.aceitaPagamento
                      ? "#BDBDBD"
                      : "#000000"
                  }
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  {item.numero}
                </Typography>
              </div>
            </TableCell>

            <TableCell sx={{ padding: "8px", height: "45px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {item.numero === 13 ? (
                  <>
                    <TextField
                      inputRef={parcela13TextFieldRef}
                      disabled={
                        item.pagamento || !payment || !parcela?.aceitaPagamento
                          ? true
                          : false ||
                            (cartItems.length > 0 &&
                              cartItems.some(
                                (cartItem) => cartItem.id === item.id
                              ))
                      }
                      value={
                        item.pagamento
                          ? formatarValorEmReais(item.pagamento.valor)
                          : parcela13Valor
                      }
                      onChange={handleParcela13ValorChange}
                      size="small"
                      variant="outlined"
                      sx={{
                        width: "74px",
                      }}
                      InputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      required={isParcela13Selected}
                    />
                    <IconButton
                      onClick={handle13Parcela}
                      sx={{
                        position: "relative",
                        right: "5px",
                        top: "-15px",
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          fontSize: "20px",
                          color: "#BDBDBD",
                          fontWeight: 700,
                        }}
                      />
                    </IconButton>
                  </>
                ) : (
                  <Typography
                    component="p"
                    variant="subtitle1"
                    color={
                      selectedItems.length > 0 &&
                      selectedItems.some(
                        (selectedItem) => selectedItem.id === item.id
                      )
                        ? "#F08C10"
                        : item.pagamento ||
                          !payment ||
                          !parcela?.aceitaPagamento
                        ? "#BDBDBD"
                        : "#000000"
                    }
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    {formatarValorEmReais(item.valor)}
                  </Typography>
                )}
              </div>
            </TableCell>
            <TableCell sx={{ padding: "8px", height: "45px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <Typography
                  component="p"
                  variant="subtitle1"
                  color={
                    selectedItems.length > 0 &&
                    selectedItems.some(
                      (selectedItem) => selectedItem.id === item.id
                    )
                      ? "#F08C10"
                      : item.pagamento || !payment || !parcela?.aceitaPagamento
                      ? "#BDBDBD"
                      : "#000000"
                  }
                  sx={{
                    fontSize: "12px",
                    textAlign: "justify",
                  }}
                >
                  {`${item.statusPagamento.descricao}`}
                </Typography>
                {!item.statusPagamento?.aceitaPagamento &&
                  item.statusPagamento?.id !== 2 &&
                  item.statusPagamento?.id !== 7 && (
                    <IconButton
                      onClick={() => handleInfo(item.codigoBarras)}
                      sx={{
                        position: "relative",
                        right: "5px",
                        top: "-15px",
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          fontSize: "15px",
                          color: "#F08C10",
                          fontWeight: 700,
                        }}
                      />
                    </IconButton>
                  )}
              </div>
            </TableCell>
            {state &&
              !exibirDetalhe &&
              !firstRender &&
              parcela?.tipoCarne === "Físico" && (
                <TableCell sx={{ padding: "8px", height: "45px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {((!item.pagamento && parcela?.aceitaPagamento) ||
                      !payment) && (
                      <IconButton
                        disabled={
                          cartItems.length > 0 &&
                          cartItems.some((cartItem) => cartItem.id === item.id)
                        }
                        onClick={() =>
                          handle2Via({
                            numeroCpf: parcela?.cpfClie,
                            numeroSerie: `${item.codigoBarrasCarne.slice(
                              0,
                              3
                            )}`,
                            numeroCarne: `${item.codigoBarrasCarne.slice(
                              3,
                              10
                            )}`,
                            numeroParcela: item.numero,
                          })
                        }
                      >
                        {!(
                          cartItems.length > 0 &&
                          cartItems.some((cartItem) => cartItem.id === item.id)
                        ) && (
                          <TextSnippetOutlinedIcon
                            sx={{
                              fontSize: "18px",
                              color:
                                selectedItems.length > 0 &&
                                selectedItems.some(
                                  (selectedItem) => selectedItem.id === item.id
                                )
                                  ? "#F08C10"
                                  : item.pagamento
                                  ? "#BDBDBD"
                                  : "#000000",
                            }}
                          />
                        )}
                      </IconButton>
                    )}
                  </div>
                </TableCell>
              )}
            {state && !exibirDetalhe && !firstRender && (
              <>
                <TableCell sx={{ padding: "8px", height: "45px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      component="p"
                      variant="subtitle1"
                      color={
                        selectedItems.length > 0 &&
                        selectedItems.some(
                          (selectedItem) => selectedItem.id === item.id
                        )
                          ? "#F08C10"
                          : item.pagamento ||
                            !payment ||
                            !parcela?.aceitaPagamento
                          ? "#BDBDBD"
                          : "#000000"
                      }
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      {item.pagamento ? item.pagamento?.localPagamento : ""}
                    </Typography>
                  </div>
                </TableCell>

                <TableCell sx={{ padding: "8px", height: "45px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <Typography
                      component="p"
                      variant="subtitle1"
                      color={
                        selectedItems.length > 0 &&
                        selectedItems.some(
                          (selectedItem) => selectedItem.id === item.id
                        )
                          ? "#F08C10"
                          : item.pagamento ||
                            !payment ||
                            !parcela?.aceitaPagamento
                          ? "#BDBDBD"
                          : "#000000"
                      }
                      sx={{
                        fontSize: "12px",
                        textAlign: "justify",
                      }}
                    >
                      {item.dataVencimento !== null &&
                      item.dataVencimento !== ""
                        ? new Date(item.dataVencimento)
                            .toLocaleString()
                            .split(",")[0]
                        : ""}
                    </Typography>
                  </div>
                </TableCell>

                <TableCell sx={{ padding: "8px", height: "45px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <Typography
                      component="p"
                      variant="subtitle1"
                      color={
                        selectedItems.length > 0 &&
                        selectedItems.some(
                          (selectedItem) => selectedItem.id === item.id
                        )
                          ? "#F08C10"
                          : item.pagamento ||
                            !payment ||
                            !parcela?.aceitaPagamento
                          ? "#BDBDBD"
                          : "#000000"
                      }
                      sx={{
                        fontSize: "12px",
                        textAlign: "justify",
                      }}
                    >
                      {item.pagamento?.data != null &&
                      item.pagamento?.data !== ""
                        ? new Date(item.pagamento?.data)
                            .toLocaleString()
                            .split(",")[0]
                        : ""}
                    </Typography>
                  </div>
                </TableCell>

                <TableCell sx={{ padding: "8px", height: "45px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {item.linkCupomDigital && (
                      <a
                        href={sanitizeLink(item.linkCupomDigital)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InsertLinkOutlinedIcon
                          sx={{
                            fontSize: "20px",
                            color: "#F08C10",
                            fontWeight: 700,
                          }}
                        />
                      </a>
                    )}
                  </div>
                </TableCell>
              </>
            )}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const handleBodyFull = () => {
    return (
      <TableBody>
        {parcela?.parcelas.map((item: any, index: any) => (
          <TableRow
            key={item.numero}
            sx={{
              "&.selected": {
                background: "#F08C10",
              },
              "&.pago": {
                "& td": {
                  color: "#BDBDBD",
                },
              },
            }}
            className={item.pagamento?.data ? "pago" : ""}
          >
            <TableCell padding="checkbox" sx={{ height: "45px" }}>
              {(!payment || parcela?.aceitaPagamento) && (
                <Checkbox
                  checked={
                    (selectedItems.length > 0 &&
                      selectedItems.some(
                        (selectedItem) => selectedItem.id === item.id
                      )) ||
                    (cartItems.length > 0 &&
                      cartItems.some((cartItem) => cartItem.id === item.id))
                  }
                  onChange={(event) =>
                    handleCheckboxChange(event, {
                      id: item.id,
                      carne: item.carne,
                      codigoBarras: item.codigoBarras,
                      codigoPix: item.codigoPix,
                      dataVencimento: item.dataVencimento,
                      idCarne: item.idCarne,
                      linkCupomDigital: item.linkCupomDigital,
                      numero: item.numero,
                      pagamento: item.pagamento,
                      statusPagamento: item.statusPagamento,
                      valor: item.valor,
                      cpfClie: item.cpfClie,
                      codigoBarrasCarne: item.codigoBarrasCarne,
                      serie: `${item.codigoBarrasCarne.slice(0, 3)}`,
                      numeroCarne: `${item.codigoBarrasCarne.slice(3, 9)}`,
                      digito: `${item.codigoBarrasCarne.slice(9, 10)}`,
                      aceitaPagamento: item.aceitaPagamento,
                    })
                  }
                  disabled={
                    !payment ||
                    !parcela?.aceitaPagamento ||
                    !item.statusPagamento?.aceitaPagamento ||
                    (cartItems.length > 0 &&
                      cartItems.some((cartItem) => cartItem.id === item.id))
                  }
                  sx={{
                    display: `${!item.pagamento ? "" : "none"}`,

                    "&.Mui-checked": {
                      color: "#F08C10",
                    },
                    "&.Mui-disabled": {
                      color: "#BDBDBD",
                    },
                  }}
                />
              )}
            </TableCell>
            <TableCell sx={{ padding: "8px", height: "45px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  component="p"
                  variant="subtitle1"
                  color={
                    selectedItems.length > 0 &&
                    selectedItems.some(
                      (selectedItem) => selectedItem.id === item.id
                    )
                      ? "#F08C10"
                      : item.pagamento || !payment || !parcela?.aceitaPagamento
                      ? "#BDBDBD"
                      : "#000000"
                  }
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  {item.numero}
                </Typography>
              </div>
            </TableCell>
            {state && (
              <TableCell sx={{ padding: "8px", height: "45px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {((!item.pagamento && parcela?.aceitaPagamento) ||
                    !payment) && (
                    <IconButton
                      disabled={
                        cartItems.length > 0 &&
                        cartItems.some((cartItem) => cartItem.id === item.id)
                      }
                      onClick={() =>
                        handle2Via({
                          numeroCpf: parcela?.cpfClie,
                          numeroSerie: `${item.codigoBarrasCarne.slice(0, 3)}`,
                          numeroCarne: `${item.codigoBarrasCarne.slice(3, 10)}`,
                          numeroParcela: item.numero,
                        })
                      }
                    >
                      {!(
                        cartItems.length > 0 &&
                        cartItems.some((cartItem) => cartItem.id === item.id)
                      ) && (
                        <TextSnippetOutlinedIcon
                          sx={{
                            fontSize: "18px",
                            color:
                              selectedItems.length > 0 &&
                              selectedItems.some(
                                (selectedItem) => selectedItem.id === item.id
                              )
                                ? "#F08C10"
                                : item.pagamento
                                ? "#BDBDBD"
                                : "#000000",
                          }}
                        />
                      )}
                    </IconButton>
                  )}
                </div>
              </TableCell>
            )}

            <TableCell sx={{ padding: "8px", height: "45px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {item.numero === 13 ? (
                  <>
                    <TextField
                      inputRef={parcela13TextFieldRef}
                      disabled={
                        item.pagamento || !payment || !parcela?.aceitaPagamento
                          ? true
                          : false ||
                            (cartItems.length > 0 &&
                              cartItems.some(
                                (cartItem) => cartItem.id === item.id
                              ))
                      }
                      value={
                        item.pagamento
                          ? formatarValorEmReais(item.pagamento.valor)
                          : parcela13Valor
                      }
                      onChange={handleParcela13ValorChange}
                      size="small"
                      variant="outlined"
                      sx={{
                        width: "84px",
                      }}
                      InputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      required={isParcela13Selected}
                    />
                    <IconButton
                      onClick={handle13Parcela}
                      sx={{
                        position: "relative",
                        right: "5px",
                        top: "-15px",
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          fontSize: "20px",
                          color: "#BDBDBD",
                          fontWeight: 700,
                        }}
                      />
                    </IconButton>
                  </>
                ) : (
                  <Typography
                    component="p"
                    variant="subtitle1"
                    color={
                      selectedItems.length > 0 &&
                      selectedItems.some(
                        (selectedItem) => selectedItem.id === item.id
                      )
                        ? "#F08C10"
                        : item.pagamento ||
                          !payment ||
                          !parcela?.aceitaPagamento
                        ? "#BDBDBD"
                        : "#000000"
                    }
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    {formatarValorEmReais(item.valor)}
                  </Typography>
                )}
              </div>
            </TableCell>
            <TableCell sx={{ padding: "8px", height: "45px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <Typography
                  component="p"
                  variant="subtitle1"
                  color={
                    selectedItems.length > 0 &&
                    selectedItems.some(
                      (selectedItem) => selectedItem.id === item.id
                    )
                      ? "#F08C10"
                      : item.pagamento || !payment || !parcela?.aceitaPagamento
                      ? "#BDBDBD"
                      : "#000000"
                  }
                  sx={{
                    fontSize: "12px",
                    textAlign: "justify",
                  }}
                >
                  {`${item.statusPagamento.descricao}`}
                </Typography>
                {!item.statusPagamento?.aceitaPagamento &&
                  item.statusPagamento?.id !== 2 &&
                  item.statusPagamento?.id !== 7 && (
                    <IconButton
                      onClick={() => handleInfo(item.codigoBarras)}
                      sx={{
                        position: "relative",
                        right: "5px",
                        top: "-15px",
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          fontSize: "15px",
                          color: "#F08C10",
                          fontWeight: 700,
                        }}
                      />
                    </IconButton>
                  )}
              </div>
            </TableCell>
            {state && (
              <>
                <TableCell sx={{ padding: "8px", height: "45px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      component="p"
                      variant="subtitle1"
                      color={
                        selectedItems.length > 0 &&
                        selectedItems.some(
                          (selectedItem) => selectedItem.id === item.id
                        )
                          ? "#F08C10"
                          : item.pagamento ||
                            !payment ||
                            !parcela?.aceitaPagamento
                          ? "#BDBDBD"
                          : "#000000"
                      }
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      {item.pagamento ? item.pagamento?.localPagamento : ""}
                    </Typography>
                  </div>
                </TableCell>

                <TableCell sx={{ padding: "8px", height: "45px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <Typography
                      component="p"
                      variant="subtitle1"
                      color={
                        selectedItems.length > 0 &&
                        selectedItems.some(
                          (selectedItem) => selectedItem.id === item.id
                        )
                          ? "#F08C10"
                          : item.pagamento ||
                            !payment ||
                            !parcela?.aceitaPagamento
                          ? "#BDBDBD"
                          : "#000000"
                      }
                      sx={{
                        fontSize: "12px",
                        textAlign: "justify",
                      }}
                    >
                      {item.dataVencimento !== null &&
                      item.dataVencimento !== ""
                        ? new Date(item.dataVencimento)
                            .toLocaleString()
                            .split(",")[0]
                        : ""}
                    </Typography>
                  </div>
                </TableCell>

                <TableCell sx={{ padding: "8px", height: "45px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <Typography
                      component="p"
                      variant="subtitle1"
                      color={
                        selectedItems.length > 0 &&
                        selectedItems.some(
                          (selectedItem) => selectedItem.id === item.id
                        )
                          ? "#F08C10"
                          : item.pagamento ||
                            !payment ||
                            !parcela?.aceitaPagamento
                          ? "#BDBDBD"
                          : "#000000"
                      }
                      sx={{
                        fontSize: "12px",
                        textAlign: "justify",
                      }}
                    >
                      {item.pagamento?.data !== null &&
                      item.pagamento?.data !== ""
                        ? new Date(item.pagamento?.data)
                            .toLocaleString()
                            .split(",")[0]
                        : ""}
                    </Typography>
                  </div>
                </TableCell>

                <TableCell sx={{ padding: "8px", height: "45px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {item.linkCupomDigital && (
                      <a
                        href={sanitizeLink(item.linkCupomDigital)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InsertLinkOutlinedIcon
                          sx={{
                            fontSize: "20px",
                            color: "#F08C10",
                            fontWeight: 700,
                          }}
                        />
                      </a>
                    )}
                  </div>
                </TableCell>
              </>
            )}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const handleDetailParcela = async (data: any) => {
    try {
      if (auth) {
        const request = await getDetail(data);
      }
    } catch (error: any) {
      setLoading(false);
      throw error;
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const updatedSelectedItems = isChecked
      ? parcela.parcelas.filter(
          (item: ParcelaData) =>
            !item.pagamento &&
            parcela?.aceitaPagamento &&
            item.statusPagamento?.aceitaPagamento &&
            item.numero !== 13
        )
      : [];

    updatedSelectedItems.forEach((item: any) => {
      item.serie = `${item.codigoBarrasCarne.slice(0, 3)}`;
      item.numeroCarne = `${item.codigoBarrasCarne.slice(3, 9)}`;
      item.digito = `${item.codigoBarrasCarne.slice(9, 10)}`;
    });

    setSelectedItems(updatedSelectedItems);
    setSelectAll(isChecked);
    setIsParcela13Selected(
      updatedSelectedItems.some((item: any) => item.numero === 13)
    );
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: ParcelaData
  ) => {
    const isChecked = event.target.checked;

    let updatedSelectedItems: ParcelaData[] = [];

    if (Array.isArray(selectedItems)) {
      if (isChecked) {
        updatedSelectedItems = [...selectedItems, item];
      } else {
        updatedSelectedItems = selectedItems.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      }
    } else {
      updatedSelectedItems = [];
    }

    setIsParcela13Selected(
      updatedSelectedItems.some((item) => item.numero === 13)
    );
    setSelectedItems(updatedSelectedItems);
  };

  const handleOcultarDetalhes = (event?: any) => {
    var updatedVisibleColumns = {
      Parcela: true,
      Valor: true,
      status: true,
      "2ª via": parcela?.tipoCarne === "Físico" && exibirDetalhe,
      "Local de Pagamento": exibirDetalhe,
      "Data de Vencimento": exibirDetalhe,
      "Data de Pagamento": exibirDetalhe,

      Cupom: exibirDetalhe,
    };

    setVisibleColumns(updatedVisibleColumns);
    setExibirDetalhe(!exibirDetalhe);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const existingCartItemsRaw = localStorage.getItem("selectedItems");
    const existingCartItems = existingCartItemsRaw
      ? JSON.parse(existingCartItemsRaw)
      : [];

    const updatedCartItems = [...existingCartItems];

    selectedItems.forEach((item) => {
      const index = updatedCartItems.findIndex(
        (selectedItems) => selectedItems.id === item.id
      );

      selectedItems.map((item: any) => {
        if (item.numero === 13 && isParcela13Selected) {
          item.valor = parseFloat(parcela13Valor.replace(",", "."));
        }
        return item;
      });

      if (index === -1) {
        updatedCartItems.push(item);
      }
    });
    setCartItems(updatedCartItems);
    localStorage.setItem("selectedItems", JSON.stringify(updatedCartItems));
    setAlertMessage("Parcela(s) adicionada(s) com sucesso!");
    setAlertStatus("success");
    setAlertOpen(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "button_click",
      click_category: "meus_carnes",
      click_text: "pagar_parcela",
    });
    if (isMobile) {
      navigate("/checkout");
    }
  };

  const handleInfo = async (item: string) => {
    try {
      if (cart) {
        const request = await cart?.infoPayment(item, tokenId);

        setInfo(request);
        handleOpen();
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handle13Parcela = () => {
    handleOpenParcela();
  };

  const handle2Via = async (item: any) => {
    const itemString = JSON.stringify(item);
    const base64Data = btoa(itemString);

    setOpenToastErrorSecond(true);
    //!verificar essa chamada
    // try {
    //   if (cart) {
    //     const request = await cart?.boleto2Via(base64Data, tokenId);
    //     downloadBase64File(
    //       "image/jpeg",
    //       `${request}`,
    //       `${item.numeroCarne}_${item.numeroParcela}.jpg`
    //     );
    //     return request;
    //   }
    // } catch (error: any) {
    //   setAlertMessage(JSON.stringify(error));
    //   setAlertStatus("error");
    //   setAlertOpen(true);
    //   throw error;
    // }
  };

  const handleParcela13ValorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.replace(/[^0-9,]/g, "");
    setParcela13Valor(value);
  };

  const mostrarBotaoOcultarDetalhes = () => {
    return (
      <Button
        onClick={handleOcultarDetalhes}
        style={{
          fontSize: "14px",
          textTransform: "none",
          fontWeight: 700,
          textAlign: "end",
          justifyContent: "end",
          background: "transparent",
          border: "none",
          marginLeft: "auto",
          display: "flex",
          color: !exibirDetalhe ? "#979797" : "#F08C10",
          textDecorationLine: "underline",
        }}
      >
        {!exibirDetalhe ? "Ocultar Detalhes" : "Consultar Detalhes"}
      </Button>
    );
  };

  const mostrarBotaoConsultarDetalhes = () => {
    return (
      <Button
        onClick={handleConsultarDetalhes}
        style={{
          fontSize: "14px",
          textTransform: "none",
          fontWeight: 700,
          textAlign: "end",
          justifyContent: "end",
          background: "transparent",
          border: "none",
          marginLeft: "auto",
          display: "flex",
          color: "#F08C10",
          textDecorationLine: "underline",
        }}
      >
        Consultar Detalhes
      </Button>
    );
  };

  const handleConsultarDetalhes = (event?: any) => {
    event.preventDefault();
    if (onClickDetalhes) {
      onClickDetalhes();
    }
    const updatedVisibleColumns: ColumnVisibility = {
      Parcela: true,
      Valor: true,
      status: true,
      "2ª via": true,
      "Local de Pagamento": true,
      "Data de Vencimento": true,
      "Data de Pagamento": true,
      Cupom: true,
    };

    setVisibleColumns(updatedVisibleColumns);
    setConsultarDetalhesAtivo(!consultarDetalhesAtivo);
    setFirstRender(false);
    setShowDetails(state);
    setState(true);
  };

  return (
    <>
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "center", md: "center" },
          }}
        >
          <Modal
            open={openModalParcela}
            onClose={handleCloseParcela}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: 288,
                height: 165,
                backgroundColor: "#FFFFFF",
                boxShadow: 24,
                p: 1,
              }}
            >
              <IconButton
                onClick={handleCloseParcela}
                sx={{
                  position: "absolute",
                  right: "0px",
                  top: "0px",
                }}
              >
                <CloseIcon
                  sx={{
                    color: "#F08C10",
                    fontWeight: 700,
                  }}
                />
              </IconButton>
              <Typography
                component="p"
                variant="subtitle1"
                color="#000000"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  lineHeight: "20px",
                  textAlign: "center",
                  fontWeight: 700,
                  margin: "20px 27px 17px 27px",
                }}
              >
                Para que serve a 13ª Parcela?
              </Typography>
              <Typography
                component="p"
                variant="subtitle2"
                color="#000000"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  lineHeight: "20px",
                  textAlign: "justify",
                  margin: "20px 27px 17px 27px",
                }}
              >
                Serve para aumentar o valor do seu resgate, e assim adquirir
                mais produtos Jequiti.
              </Typography>
            </Box>
          </Modal>
          <Modal
            open={openModal}
            onClose={handleClose}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: 385,
                height: `${info?.qrCode ? "430px" : "216px"}`,
                backgroundColor: "#FFFFFF",
                boxShadow: 24,
                p: 1,
              }}
            >
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                }}
              >
                <CloseIcon
                  sx={{
                    color: "#F08C10",
                    fontWeight: 700,
                  }}
                />
              </IconButton>
              <Typography
                component="p"
                variant="subtitle1"
                color="#000000"
                sx={{
                  fontSize: "16px",
                  lineHeight: "20px",
                  textAlign: "justify",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700,
                  margin: "20px 27px 17px 27px",
                }}
              >
                Olá {`${userValue?.nome}`},
              </Typography>
              <Typography
                component="p"
                variant="subtitle1"
                color="#000000"
                sx={{
                  fontSize: "12px",
                  lineHeight: "20px",
                  textAlign: "justify",
                  margin: "20px 27px 17px 27px",
                }}
              >
                <p>
                  Esta parcela está <b>{`${info?.statusPagamento}`}</b>,
                  aguardando a confirmação do pagamento.
                </p>
                <p>
                  Caso o pedido nº <b>{`#${info?.numeroPedido}`}</b>, de{" "}
                  <b>{`${formatDate(info?.dataPedido)}`}</b>, em{" "}
                  {`${info?.qrCode ? "PIX" : "cartão de crédito"}`} não seja
                  autorizado, seu pedido será cancelado e as parcelas retornarão
                  para o status de Em Aberto.
                </p>
              </Typography>
              {info?.qrCode && (
                <>
                  <Typography
                    variant="subtitle1"
                    color="#000000"
                    onClick={handleCopyPix}
                    sx={{
                      fontSize: "12px",
                      lineHeight: "20px",
                      textAlign: "center",
                      fontWeight: 700,
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                  >
                    {`${copiedPix ? "Copiado" : "Copie Cola"}`}
                  </Typography>
                  <Input
                    name="pix"
                    value={info?.qrCode}
                    multiline
                    sx={{
                      margin: "20px 27px 17px 27px",
                      width: 310,
                      height: 150,
                      color: "#575756",
                    }}
                  />
                </>
              )}
            </Box>
          </Modal>
          <Box sx={{ width: { xs: "310px", sm: "454px", lg: "700px" } }}>
            <div style={{ margin: `${isMobile ? "0 10px" : ""}` }}>
              <Typography
                variant="h1"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIosIcon
                  onClick={() => setView(false)}
                  sx={{
                    fontSize: "32px",
                    cursor: "pointer",
                  }}
                />

                {tableTitle()}
              </Typography>
              <Typography
                variant="subtitle1"
                color="#000000"
                sx={{
                  marginBottom: "30px",
                  width: { xs: "310px", sm: "360px", md: "454px" },
                }}
              >
                <b>Acompanhe</b> seu extrato de pagamento.
              </Typography>
            </div>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    color="#1C1C7B"
                    sx={{
                      textTransform: "uppercase",
                      marginTop: "20px",
                      fontSize: "10px",
                      fontWeight: 700,
                      textAlign: "center",
                    }}
                  >
                    Número de série
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" style={{ height: "62px" }} />
                  ) : (
                    <>
                      <TextField
                        disabled={true}
                        value={parcela?.codBarrasCarne.substring(0, 3)}
                        size="small"
                        variant="outlined"
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            textAlign: "center",
                            "&.Mui-disabled": {
                              "-webkit-text-fill-color": "#000000",
                            },
                          },
                        }}
                        InputProps={{
                          style: {
                            fontSize: "14px",
                            height: "48px",
                            fontWeight: 700,
                          },
                        }}
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    color="#1C1C7B"
                    sx={{
                      textTransform: "uppercase",
                      marginTop: "20px",
                      fontSize: "10px",
                      fontWeight: 700,
                      textAlign: "center",
                    }}
                  >
                    Número do carnê
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" style={{ height: "62px" }} />
                  ) : (
                    <TextField
                      disabled={true}
                      value={parcela?.codBarrasCarne.substring(3, 10)}
                      size="small"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          textAlign: "center",
                          "&.Mui-disabled": {
                            color: "#000000",
                            "-webkit-text-fill-color": "#000000",
                          },
                        },
                      }}
                      InputProps={{
                        style: {
                          fontSize: "14px",
                          height: "48px",
                          fontWeight: 700,
                        },
                      }}
                    />
                  )}

                  {!consultarDetalhesAtivo
                    ? mostrarBotaoConsultarDetalhes()
                    : mostrarBotaoOcultarDetalhes()}
                </Grid>
              </Grid>
              <div
                style={{
                  overflowX: "auto",
                  maxWidth: "100%",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                {loading ? (
                  <SkeletonParcelas />
                ) : (
                  <Table size="small" stickyHeader aria-label="a dense table">
                    <TableHead>
                      <TableRow style={{ height: "20px" }}>
                        <TableCell padding="checkbox">
                          {payment &&
                            parcela?.quantidadeDeParcelasPagas < 12 && (
                              <Checkbox
                                disabled={
                                  !parcela?.aceitaPagamento ? true : false
                                }
                                checked={selectAll}
                                onChange={handleSelectAll}
                                sx={{
                                  color: "#1C1C7B",
                                  "&.Mui-checked": {
                                    color: "#F08C10",
                                  },
                                }}
                              />
                            )}
                        </TableCell>
                        {Object.keys(visibleColumns).map((columnName) => {
                          if (
                            visibleColumns[columnName as keyof ColumnVisibility]
                          ) {
                            if (columnName === "Data de Vencimento") {
                              return (
                                <TableCell
                                  key={columnName}
                                  sx={{
                                    padding: "8px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      component="p"
                                      variant="subtitle1"
                                      fontWeight={700}
                                      color="#1C1C7B"
                                      sx={{
                                        fontSize: "10px",
                                        textTransform: "uppercase",
                                        marginBottom: "4px",
                                      }}
                                    >
                                      {columnName}
                                    </Typography>
                                  </div>
                                  <CustomTooltip
                                    title={
                                      <>
                                        <Typography
                                          style={{
                                            display: "flex",
                                            textAlign: "center",
                                            margin: "auto",
                                            justifyContent: "center",
                                            paddingBottom: 10,
                                            color: "#000",
                                            fontWeight: 700,
                                          }}
                                          fontSize={16}
                                        >
                                          Data de Vencimento
                                        </Typography>
                                        <p
                                          style={{
                                            fontSize: 14,
                                            alignItems: "center",
                                          }}
                                        >
                                          Este campo serve para orientá-lo
                                          quanto a data de pagamento mensal de
                                          sua parcela. A{" "}
                                          <strong>
                                            sugestão de vencimento
                                          </strong>
                                          , é calculada a partir da data do
                                          primeiro pagamento do carnê,
                                          independente da parcela que foi paga.
                                          Após a realização do pagamento,
                                          atualizaremos essa informação,
                                          substituindo pela data real de
                                          pagamento.
                                          <br />
                                          <br />
                                          <i>
                                            {" "}
                                            Mantenha seus pagamentos sempre em
                                            dia (conforme sugestão de
                                            vencimento) para participar dos
                                            sorteios do Baú da Felicidade.
                                          </i>
                                        </p>
                                      </>
                                    }
                                  >
                                    <img
                                      src={`${process.env.REACT_APP_IMAGE_SERVER_URL}info.svg`}
                                      alt="info"
                                    />
                                  </CustomTooltip>
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell
                                  key={columnName}
                                  sx={{
                                    padding: "8px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent:
                                        columnName === "status"
                                          ? "left"
                                          : "center",
                                    }}
                                  >
                                    <Typography
                                      component="p"
                                      variant="subtitle1"
                                      fontWeight={700}
                                      color="#1C1C7B"
                                      sx={{
                                        fontSize: "10px",
                                        textTransform: "uppercase",
                                        marginBottom: "4px",
                                      }}
                                    >
                                      {columnName}
                                    </Typography>
                                  </div>
                                </TableCell>
                              );
                            }
                          }
                          return null;
                        })}
                      </TableRow>
                    </TableHead>
                    {!consultarDetalhesAtivo && !firstRender
                      ? handleBodyFull()
                      : handleDetailBody()}
                  </Table>
                )}
              </div>
              {payment && (
                <Grid
                  container
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "center",
                        sm: "center",
                        md: "center",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "380px",
                          md: "454px",
                          lg: "700px",
                        },
                        display: "flex",
                        justifyContent: "center",
                        position: `${isMobile ? "fixed" : "static"}`,
                        bottom: 1,
                        zIndex: 2,
                      }}
                    >
                      <Button
                        type="submit"
                        id="btn-pagar-parcelas-selecionadas"
                        disabled={
                          loading ||
                          !parcela?.aceitaPagamento ||
                          selectedItems.length < 1 ||
                          (isParcela13Selected && parcela13Valor < "0,01")
                        }
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "100%",
                            md: "454px",
                            lg: "700px",
                          },
                          height: "48px",
                          fontSize: "14px",
                          color: "#FFFFFF",
                          zIndex: 2,
                          background: `${
                            loading ||
                            !parcela?.aceitaPagamento ||
                            selectedItems.length < 1 ||
                            (isParcela13Selected && parcela13Valor < "0,01")
                              ? "#BDBDBD"
                              : "#F08C10"
                          }`,

                          "&: hover": {
                            background: "#F08C10",
                          },
                        }}
                      >
                        {loading ? (
                          <CircularProgress sx={{ color: "#FFFFFF" }} />
                        ) : (
                          `PAGAR PARCELAS SELECIONADAS`
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "15px 0 200px 0",
              }}
            >
              <Typography
                color="#1C1C7B"
                onClick={() => setView(false)}
                sx={{
                  cursor: "pointer",
                  fontSize: "13px",
                  fontWeight: 700,
                  lineHeight: "16px",
                }}
              >
                {"< Voltar para Pagar Parcela"}
              </Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={alertOpen}
        autoHideDuration={5000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setAlertOpen(false)}
          severity={alertStatus || "info"}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {/* const [openToastErrorSecond, setOpenToastErrorSecond] = useState(false); */}

      <Toast
        type="error"
        open={openToastErrorSecond}
        setOpen={setOpenToastErrorSecond}
        title="Ocorreu um erro ao gerar a segunda via do  boleto. Tente novamente mais tarde."
      />
    </>
  );
};

export default Parcelas;
