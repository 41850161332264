export const arrDescription = [
  {
    label: 'carnê do baú',
    image: `${process.env.REACT_APP_IMAGE_SERVER_URL}carnequemsomos.svg`,
    description: `
                  <p><strong>O carnê do Baú da Felicidade é Jequiti!</strong></p>
                  <p>
                    A Jequiti, uma empresa do Grupo Silvio Santos, trouxe para você um produto conhecido em todo o
                    Brasil: O CARNÊ DO BAÚ DA FELICIDADE. Com enorme credibilidade e muito prestígio entre os
                    brasileiros já premiou milhares de pessoas em todos os estados brasileiros.
                  </p>
                `,
  },
  {
    label: 'Sorteio Baú',
    image: `${process.env.REACT_APP_IMAGE_SERVER_URL}sorteiobau.svg`,
    description: `
                  <p><strong>Sorteio do Baú da Felicidade no SBT</strong></p>
                  <p>
                    Os sorteios do Baú acontecem diariamente no SBT e milhares de clientes em todos os estados
                    brasileiros já foram contemplados. São 90 sorteios todos os meses envolvendo carros,
                    smartphones, salários, eletrodomésticos e muito dinheiro.
                  </p>
                `,
  },
  {
    label: 'Resgate Carnê',
    image: `${process.env.REACT_APP_IMAGE_SERVER_URL}resgatebau.svg`,
    description: `
                  <p><strong>Resgate produtos Jequiti</strong></p>
                  <p>
                    No final do pagamento das mensalidades do carnê do Baú você receberá o seu dinheiro de volta em produtos Jequiti.
                    As 12 mensalidades pagas somam R$240,00 e o Baú dá R$24,00, formando R$264,00, para serem resgatados em produtos Jequiti.
                    O resgate dos produtos pode ser realizado diretamente no <a href='https://bau.jequiti.com.br/' target='_blank'>www.bau.jequiti.com.br</a>, no whatsapp (11) 95302 0985
                    ou pela central de atendimento exclusiva do Baú no 0800 545 2121.
                  </p>
                `,
  },
  {
    label: 'Cadastro Carnê',
    image: `${process.env.REACT_APP_IMAGE_SERVER_URL}cadastrocarne.svg`,
    description: `
                  <p><strong>Cadastre o carnê do Baú em seu nome</strong></p>
                  <p>
                    Vincule imediatamente o carnê do Baú ao seu nome aqui no site
                    <a href='https://bau.jequiti.com.br/identificacao/' target='_blank'>bau.jequiti.com.br/cadastro</a>,
                    no Whatsapp <a href='https://wa.me/11953020985' target='_blank'>(11) 95302 0985</a>,
                    pelo telefone 0800 545 2121 ou preenchendo o cupom de cadastro no
                    carnê impresso do Baú para enviar na Caixa Postal 05909-960. O cadastro é fundamental para
                    garantir a localização e contato dos ganhadores.
                  </p>
                  <p>
                    Se você já tem outros carnês, basta entrar no
                    <a href='https://bau.jequiti.com.br/' target='_blank'>www.bau.jequiti.com.br</a> ou ligar no telefone 0800 545 2121
                    para “vincular” o novo carnê ao seu CPF. No caso dos carnês solicitados pelo site, telefone,
                    WhatsApp ou o enviado na caixa de resgate, não é necessário fazer o cadastro.
                  </p>
                `,
  },
  {
    label: 'Dinâmica Carnê',
    image: `${process.env.REACT_APP_IMAGE_SERVER_URL}dinamicacarne.svg`,
    description: `
                  <p><strong>Dinâmica do carnê do Baú da Felicidade</strong></p>
                  <ol>
                    <li>
                      Frete grátis a partir da 10º parcela.
                    </li>
                    <li>
                      Não existe multa ou juros sobre as parcelas atrasadas.
                    </li>
                    <li>
                      O pagamento das parcelas do carnê do Baú da Felicidade pode ser realizado nas Casas Lotéricas,
                      Agências dos Correios, Lojas Lebes, no site <a href='https://bau.jequiti.com.br/' target='_blank'>www.bau.jequiti.com.br</a> ou no
                      0800 545 2121 com cartão de crédito.
                    </li>
                    <li>
                      O resgate dos produtos pode ser realizado diretamente no <a href='https://bau.jequiti.com.br/' target='_blank'>www.bau.jequiti.com.br</a>,
                      no whatsapp <a href='https://wa.me/11953020985' target='_blank'>(11) 95302 0985</a> ou pela central de atendimento exclusiva do Baú no 0800 545 2121.
                    </li>
                  </ol>
                `,
  },
]