export const politicas = [
  {
    titulo: 'POLÍTICA DE TROCAS',
    label: 'Trocas e Devoluçoes',
    url: '/politicas-legais',
    id: '0',
    doc: 'institucional/Politica_troca_bau.pdf',
    description: `
                      <p>
                        O USUÁRIO poderá exercer o seu direito de arrependimento, bem como solicitar a troca ou
                        devolução do(s) produto(s) adquirido(s) em até 07 (sete) dias úteis contados do seu recebimento.
                        Pedimos ao USUÁRIO que acesse nossos canais de atendimento para maiores informações sobre como
                        proceder e os procedimentos envolvidos.
                        A mercadoria deverá ser devolvida através do serviço de postagem em qualquer agência dos Correios.
                        A autorização de postagem é totalmente sem custos para o cliente.
                      </p>
                      <p>
                        O produto deve ser devolvido em sua embalagem original, sem qualquer indício de uso,
                        e acompanhado de todos os acessórios e manuais.
                      </p>
                      <p>
                        O produto que não atender às condições exigidas acima, não será aceito como devolução
                        e automaticamente será remetido de volta ao endereço de origem.
                      </p>
                      <p>
                        Para saber mais, acesse nossos canais de atendimento:<br><br>

                        - 0800 545 2121 (ligação gratuita). Todas as regiões.<br>
                        - WhatsApp BAÚ <a href='https://wa.me/11953020985' target='_blank'>(11) 95302 0985</a>
                      </p>
       `,
  },
  {
    titulo: 'TERMOS E CONDIÇÕES',
    label: 'Termos e Condições',
    url: '/politicas-legais',
    id: '1',
    doc: 'institucional/Politica_condicoes_bau.pdf',
    description: `
                      <p>
                        Obrigado por acessar o site do <strong>BAÚ da Felicidade Jequiti!</strong><br>
                        A seguir apresentamos a você (USUÁRIO) as principais regras para a utilização e efetivação
                        de compras em nosso site. Como condição para acesso e uso do site bau.com.br, o USUÁRIO deve
                        declarar que fez a leitura completa e atenta das regras deste documento e de sua Política de
                        Privacidade, estando plenamente ciente e de acordo com elas.
                      </p>
                      <p>
                        <strong>1.	INFORMAÇÕES GERAIS SOBRE O SITE DO BAU DA FELICIDADE JEQUITI</strong><br>
                        1.1.	o BAÚ da Felicidade passa a disponibilizar a possibilidade de compras de carnês e
                        resgate de produtos para clientes finais através da plataforma digital hospedada no
                        endereço eletrônico bau.com.br. As condições comerciais aqui aplicadas são exclusivas para
                        vendas para consumidor final (Pessoa Física) por meio da plataforma digital.
                        Não há obrigatoriedade do BAÚ da Felicidade Jequiti na aplicação das condições
                        apresentadas neste ambiente de compras virtuais para seus outros canais de vendas.
                      </p>
                      <p>
                        <strong>1.2.	CONDIÇÕES GERAIS<br></strong>
                        1.2.1.	O USUÁRO poderá efetuar suas compras e resgates de carnês no site do BAÚ da Felicidade
                        Jequiti realizando um novo cadastro ou através de seu acesso por login e senha cadastrados
                        anteriormente.<br>
                        1.2.2.	No momento da compra de carnês ou resgate de produtos, o USUÁRIO terá acesso aos
                        produtos disponíveis de acordo com o episódio vigente, valido também para eventuais bônus
                        e promoções exclusivas, tais como, cupons para participação em sorteios dos programas
                        televisivos entre outros benefícios.<br>
                        1.2.3.	Para ter acesso aos benefícios acima, o CLIENTE BAÚ não poderá estar com as
                        mensalidades do carnê em atraso, ou seja, não poderá estar há mais de 30 dias sem efetuar
                        o pagamento do(s) carnê(s) adquirido(s).<br>
                        1.2.4.	O CLIENTE BAÚ terá a sua disposição todo o catálogo de produtos, incluindo
                        lançamentos e algumas promoções disponíveis no momento do resgate. No entanto,
                        não serão aplicadas as condições especiais como, cupons para a participação nos
                        sorteios dos programas televisivos, redução/isenção do frete e etc.
                        Exceto em casos eventuais promoções com vigência pré-definida e comunicadas no site.
                      </p>
       `,
  },
  {
    titulo: 'PRAZOS DE ENTREGA',
    label: 'Prazos de Entrega',
    url: '/politicas-legais',
    id: '2',
    doc: 'institucional/Politica_entrega_bau.pdf',
    description: `
                      <p>
                        Após escolher o(s) produto(s) que deseja adquirir, o USUÁRIO terá à disposição diversos
                        tipos de frete, os quais podem variar de acordo com sua localização e tipo de produto adquirido.
                        O processo de entrega do(s) produto(s) adquirido(s) se dará após a confirmação de localidade
                        da entrega e aceite dos termos de políticas de entrega do BAÚ da Felicidade Jequiti.
                        Mais informações a respeito dos tipos de frete, bem como o procedimento de entrega podem
                        ser obtidas na Central de Atendimento 0800 545 2121 (Ligação gratuita) e através
                        do WhatsApp BAÚ <a href='https://wa.me/11953020985' target='_blank'>(11) 95302 0985</a>
                      </p>
                      <p>
                        A entrega normal é realizada de segunda à sexta-feira das 8h às 20h. O USUÁRIO saberá o
                        prazo de entrega ao digitar seu CEP no momento de finalização da sua compra. Após aprovação,
                        o USUÁRIO também receberá um e-mail informando o status do seu pedido, o prazo de entrega
                        e link com tracking para acompanhamento de todas as etapas de entrega do pedido.
                      </p>
                      <p>
                        O frete relacionado a compra do carnê do Baú da Felicidade Jequiti impresso poderá ser
                        gratuito ou seguirá promoção vigente.
                      </p>
       `,
  },
  {
    titulo: 'POLÍTICA DE PRIVACIDADE',
    label: 'Política de Privacidade',
    url: '/politicas-legais',
    id: '3',
    doc: 'institucional/Politica_privacidade.pdf',
    description: `
    <h3>POLÍTICA DE PRIVACIDADE</h3>
    </br>
                      <p>
                       O BAÚ da Felicidade Jequiti respeita a sua privacidade e está comprometido com a proteção
dos seus dados pessoais. Afirmando este compromisso, nesta Política de Privacidade
descrevemos como coletamos, usamos, compartilhamos e protegemos as informações pessoais
que você nos fornece por meio do nosso site.
                      </p>
  </br>
                      <p>
                      <strong>DEFINIÇÕES</strong>
                      </p>
                         <p>
                      <strong>Autoridade Nacional de Proteção de Dados – ANPD:</strong>
                      Órgão do Governo Federal responsável
por zelar e fiscalizar o cumprimento da Lei Geral de Proteção de Dados (LGPD)
                      </p>

                         <p>
                      <strong>Consentimento:</strong>
                      manifestação livre, informada e inequívoca pela qual o titular concorda com o
tratamento de seus dados pessoais para uma finalidade determinada.

                      </p>
                         <p>
                      <strong>Controlador:</strong>
                      pessoa singular ou coletiva, autoridade pública, agência que, isoladamente ou em
conjunto com outros, determina as finalidades e meios do tratamento de dados pessoais.
                      </p>
                         <p>
                      <strong>Cookies: </strong>
                      são pequenos arquivos de texto que podem ser inseridos em seu computador ou
dispositivo portátil por sites ou serviços na web que armazenam as preferências e outras
informações, com a finalidade de personalizar sua navegação de acordo com o seu perfil.
                      </p>

                                     <p>
                      <strong>Dado Pessoal:</strong>
                     informação relacionada a pessoa natural identificada ou identificável.
                      </p>

       `,
  },
]